import React from "react";
import { useEffect, useState } from "react";
import "../Login/index.less";
import {
	Layout,
	Typography,
	Collapse,
	Input,
	Button,
	Form,
	Row,
	Col,
} from "antd";
const { Content } = Layout;
const { Title } = Typography;
const { Panel } = Collapse;
import iconFilter from "@/assets/icon/icon_search.png";
import { useMediaQuery } from "react-responsive";
import { SearchOutlined } from "@ant-design/icons";
import { EGet, NFAQ, NAnnouncement, NTopics } from "../../models/contants";
import { effect, useStore } from "@/utils/dva16";
import { Spin } from "antd";
import headerImg from "../../assets/header.png";
const FAQpage = (props) => {
	const { faqList, load } = useStore(NFAQ);
	const { announcementList, loadAnnouncement } = useStore(NAnnouncement);
	const { topicsList, loadTopics } = useStore(NTopics);
	const [form] = Form.useForm();
	const [searchValues, setSearchValues] = useState({});
	const { history } = props;
	const isSmallScreen = useMediaQuery({ maxWidth: 768 });
	useEffect(() => {
		effect(NFAQ, EGet, { page: 1, perPage: 10 });
		effect(NAnnouncement, EGet, { page: 1, perPage: 10 });
		effect(NTopics, EGet, { page: 1, perPage: 10 });
	}, []);

	const onSearch = (value) => {
		setSearchValues(value);
		effect(NFAQ, EGet, {
			page: 1,
			perPage: 10,
			...value,
		});
	};

	const onChange = (key) => {
		// console.log(key);
	};
	
	return (
		<Layout className="container">
			<header className="header">
				<div onClick={() => history.push("/")}>
					<img src={headerImg} className="headerimg" />
				</div>
			</header>

			<Content className="contentfaq">
				<h3
					className="pageTitle"
					style={{ fontSize: isSmallScreen ? "2rem" : "3rem" }}
				>
					Frequently Asked Questions
				</h3>

				<Form form={form} onFinish={onSearch}>
					{" "}
					<Row
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<Form.Item name="question" labelCol={{ span: 24 }}>
							<Input
								style={{ width: "100%" }}
								className="customInputBlog"
								placeholder="Search by question"
							/>
						</Form.Item>
						<Button type="primary" htmlType="submit" className="applySearchBtn">
							<img src={iconFilter} />
						</Button>
					</Row>
				</Form>
				<div>
					<Collapse onChange={onChange} className="faqAnswer">
						{faqList?.data?.map((i, k) => {
							return (
								<Panel header={i?.question} key={k}>
									<p className="faqAnswer">{i?.answer}</p>
								</Panel>
							);
						})}
					</Collapse>
				</div>
				{announcementList?.data?.length > 0 && (
					<div>
						<h3>Announcements:</h3>
						<div className="orangeLine"></div>
						<Collapse onChange={onChange} className="faqAnswer">
							{announcementList?.data?.map((i, k) => {
								return (
									<Panel header={i?.title} key={k}>
										<p className="faqAnswer">{i?.content}</p>
									</Panel>
								);
							})}
						</Collapse>
					</div>
				)}
				{topicsList?.data?.length > 0 && (
					<div>
						<h3>Topics:</h3>
						<div className="orangeLine"></div>
						<div className="topics">
							{topicsList?.data?.map((topic) => {
								return (
									<div className="topicsContainer">
										<p className="topicTitle">{topic?.title}</p>
										<p className="topicContent">{topic?.content}</p>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</Content>
		</Layout>
	);
};

export default FAQpage;
