import { requestGet, requestPost } from "../utils/dva16";
import { NExclusives, EGet, RSetState, EPost, EGetActive } from "./contants";

const Genre = {
	namespace: NExclusives,
	state: {
		loading: false,
		exclusivesList: null,
		activeNew: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { reducer }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("exclusives", payload);
			reducer(RSetState, { exclusivesList: res, loading: false });
		},
		async [EGetActive]({ payload, callback }, { reducer }) {
			let res = await requestGet("exclusives/show/getActive", payload);
			reducer(RSetState, { activeNew: res });
		},
		//分享或者下载
		async [EPost]({ payload, callback }, { reducer }) {
			await requestPost("exclusives/operate", payload);
			callback && callback();
		},
	},
};
export default Genre;
