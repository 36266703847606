import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import formatDate from "@/utils/dateFormater";
import { useLocation } from "react-router-dom";
import "./index.less";
import "../Profile/index.less";
import iconFilter from "@/assets/icon/icon_search.png";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
import CustomModal from "../../../components/modal";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	DatePicker,
	Select,
	Spin,
	Tag,
	Input,
	InputNumber,
	Pagination,
	Typography,
	Collapse,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGetArea,
	EGet,
	EGetOne,
	EGetCollect,
	NTournament,
	NGame,
	NPlatform,
	EGetType,
	NOrganizationMulti,
	NRegion,
	NLogin,
	NTournamentType,
	NSpecialCharacteristics,
	EGetRegion,
} from "../../../models/contants";
const { RangePicker } = DatePicker;
const { Option } = Select;
import { customizeRenderEmpty } from "../../../utils/utils";
import CustomEmpty from "@/utils/CustomEmpty";
import { Helmet } from "react-helmet";

export default (props) => {
	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const { history } = props;
	const {
		location: { state },
	} = history;
	const { Text } = Typography;
	const [form] = Form.useForm();
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const [keyword, setKeyword] = useState({});
	const { organizationSearch } = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const { PlatformData } = useStore(NPlatform);
	const { TournamentList, loading } = useStore(NTournament);
	const { tournamentTypeList } = useStore(NTournamentType);
	const { specialCharacteristicsList } = useStore(NSpecialCharacteristics);
	const [filterValues, setFilterValues] = useState([]);
	const [sortState, setSortState] = useState(null);
	const paginationLocale = {
		items_per_page: "per page",
	};
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	const [showAllTags, setShowAllTags] = useState({});
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [sortedInfo, setSortedInfo] = useState({});
	const columns = [
		{
			title: "Tournament",
			dataIndex: "tournament",
			key: "tournament",
			align: "left",
			width: "250px",
			showSorterTooltip: false, // Hide the default sorter tooltip
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<a className="a_style" href={`#/esports/details/${record?.id}`}>
									{text}
								</a>
							</p>
						</div>
					</div>
				</>
			),
			style: {
				color: sortedInfo.columnKey === "tournament" ? "red" : "inherit",
			},
			sortOrder: sortState?.field == "tournament" && sortState?.order,
			sorter: (a, b) => a?.tournament?.localeCompare(b?.tournament),
		},
		{
			title: "Host Area/Region",
			dataIndex: "area",
			key: "area",
			showSorterTooltip: false, // Hide the default sorter tooltip
			align: "left",
			width: "250px",

			render: (text, record) => (
				<div>
					{record?.area?.length
						? // ? record?.area?.map((vo, io) => <div key={io}>{vo}</div>)
						  record?.area?.join(", ")
						: "-"}
					<br />
					{record?.region?.length
						? // record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						  record?.region?.join(", ")
						: "-"}
				</div>
			),
			sortOrder: sortState?.field == "area" && sortState?.order,
			sorter: (a, b) => {
				a?.area?.[0] &&
					b?.area?.[0] &&
					a?.area?.[0]?.localeCompare(b?.area?.[0]);
			},
		},
		{
			title: "Game",
			dataIndex: "game",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "game",
			align: "center",
			width: 200,
			className: "custom-publisher-column",
			render: (text, record, index) => {
				const showAll = showAllTags[index] || false;
				return (
					<div>
						{record?.withGameList
							?.slice(0, showAll ? record.withGameList.length : 3)
							?.map((value, index) => {
								return (
									<>
										<Tag
											className="greenTag"
											key={index}
											onClick={() => {
												history.push(
													`/games_organizations/games/details/${value?.id}`
												);
											}}
										>
											{value?.gameEn + "\n"}
										</Tag>
									</>
								);
							})}
						{(record?.withGameList?.length > 3 ||
							record?.withCompanys?.length > 3 ||
							record?.withTeams?.length > 3 ||
							record?.withPlatforms?.length > 3 ||
							record?.genres?.length > 3) && (
							<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
								{showAll ? "Show less..." : "Show all..."}
							</Tag>
						)}
					</div>
				);
			},
			sortOrder: sortState?.field == "game" && sortState?.order,
			sorter: (a, b) => {
				a?.withGameList?.[0]?.gameEn &&
					b?.withGameList?.[0]?.gameEn &&
					a?.withGameList?.[0]?.gameEn?.localeCompare(
						b?.withGameList?.[0]?.gameEn
					);
			},
		},
		{
			title: "Prize Pool",
			showSorterTooltip: false, // Hide the default sorter tooltip
			dataIndex: "prizePool",
			key: "prizePool",
			width: 200,
			align: "left",
			render: (text, value, index) => (
				<>
					{text
						? "$" + text?.toString()?.replace(/(\d)(?=(\d{3})+\b)/g, "$1,")
						: "-"}
				</>
			),
			sortOrder: sortState?.field == "prizePool" && sortState?.order,
			sorter: (a, b) => Number(a?.prizePool) - Number(b?.prizePool),
		},
		{
			title: "Platforms",
			dataIndex: "platforms",
			key: "platforms",
			showSorterTooltip: false, // Hide the default sorter tooltip
			width: 200,
			align: "left",
			render: (text, value, index) => (
				<>
					{value?.platforms &&
						value?.platforms?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
			sortOrder: sortState?.field == "platforms" && sortState?.order,
			sorter: (a, b) =>
				a?.platforms &&
				b?.platforms &&
				a?.platforms?.[0]?.localeCompare(b?.platforms?.[0]),
		},
		{
			title: "Scope",
			dataIndex: "scope",
			showSorterTooltip: false, // Hide the default sorter tooltip
			key: "scope",
			width: 200,
			align: "left",
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<span>{text || "-"}</span>
							</p>
						</div>
					</div>
				</>
			),
			sortOrder: sortState?.field == "scope" && sortState?.order,
			sorter: (a, b) => a?.scope?.localeCompare(b?.scope),
		},
		{
			title: "Venue",
			dataIndex: "venue",
			key: "venue",
			showSorterTooltip: false, // Hide the default sorter tooltip
			align: "left",
			width: "100px",
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<span>{text || "-"}</span>
							</p>
						</div>
					</div>
				</>
			),

			sortOrder: sortState?.field == "venue" && sortState?.order,
			sorter: (a, b) => a?.venue?.localeCompare(b?.venue),
		},

		{
			title: "Start Date",
			dataIndex: "startDate",
			key: "startDate",
			showSorterTooltip: false, // Hide the default sorter tooltip
			width: "200px",
			align: "left",
			render: (text, record, index) => {
				const date = new Date(record?.startDate);
				const formattedDate = formatDate(date);
				return <div>{record?.startDate ? formattedDate : ""} </div>;
			},
			sortOrder: sortState?.field == "startDate" && sortState?.order,
			sorter: (a, b) => {
				const dateA = new Date(a?.startDate);
				const dateB = new Date(b?.startDate);
				return dateA - dateB;
			},
		},
	];

	const handleChange = (pagination, filters, sorter) => {
		setSortedInfo(sorter);
	};
	const location = useLocation();

	useEffect(() => {
		// const targetElement = document.getElementById("targetAnchor");
		// if (targetElement) {
		// 	targetElement.scrollIntoView({ behavior: "smooth" });
		// }
	}, [location]);
	useEffect(() => {
		if (state) {
			form.setFieldsValue({
				...state,
			});
			if (state?.company) {
				form.setFieldsValue({
					company: state?.company,
				});
				setFilterValues((o) => [...o, state.company]);

				handleValuesChange(state);
			}
			if (state.game) {
				setFilterValues((o) => [...o, state.game]);
				form.setFieldsValue({
					game: state?.name,
				});
				handleValuesChange(state);
			}
		}
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 100 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetType, { page: 1, perPage: 10 });
		effect(NTournamentType, EGet, { page: 1, perPage: 100 });
		effect(NSpecialCharacteristics, EGet, { page: 1, perPage: 100 });
		return () => {};
	}, []);
	useEffect(() => {
		let page = sessionStorage.getItem("tournamentPage");
		let pageSize = sessionStorage.getItem("tournamentPageSize");
		if (page && pageSize) {
			effect(NTournament, EGet, { page, pageSize });
		} else {
			effect(NTournament, EGet, { page: 1, pageSize: 10, ...state });
		}
		effect(NTournament, EGetCollect, {});
		effect(NTournament, EGetOne, {});
		effect(NLogin, EGet, {});
	}, []);
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetType, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchTournament = (value) => {
		effect(NTournament, EGetType, {
			...{ tournament: value },
			page: 1,
			perPage: 10,
		});
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		if (state?.company) {
			values.company = state?.company;
		}
		if (state?.game) {
			values.game = state?.game;
		}
		values.startDate = values?.startDate
			? moment(values?.startDate?._d)?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.endDate
			? moment(values?.endDate?._d)?.format("YYYY-MM-DD")
			: null;
		delete values?.date;
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		setFilterValues(valuesArray);

		setKeyword(values);
		closeModal();
		effect(NTournament, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};

	const onReset = () => {
		sessionStorage.setItem("tournamentPage", 1);
		sessionStorage.setItem("tournamentPageSize", 10);
		setKeyword();
		setFormValues([]);
		form.resetFields();
		effect(NTournament, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetType, { page: 1, perPage: 10 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		setSortState(null);
		closeModal();
		setFilterValues([]);
	};
	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "area") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];

		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});
		setRegionArr(Array.from(new Set(updatedRegionArr)));
		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
		setFormValues((prevValues) => ({
			...prevValues,
			["region"]: Array.from(new Set(updatedRegionArr)),
		}));
	}
	const onPageChange = (page, pageSize, sort) => {
		if (page && pageSize) {
			effect(NTournament, EGet, {
				...keyword,
				page: page,
				perPage: pageSize,
				field: sortState && sortState?.field,
				isDesc: (sortState && sortState?.order) || null,
			});
			sessionStorage.setItem("tournamentPage", page);
			sessionStorage.setItem("tournamentPageSize", pageSize);
		}
	};
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div>
				<p className="mainheader" id="targetAnchor">
					Esports
				</p>
				<div className="subHeader">
					Filter the knowledge base for Esports data.
				</div>
			</div>
			{userInfo?.type === "Admin" ||
			(currentSubscribe?.[0]?.module.hasOwnProperty("Esport") &&
				currentSubscribe?.[0]?.module["Esport"]?.length > 0) ? (
				<>
					<div className="searchBlog">
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<Form
								onValuesChange={handleValuesChange}
								form={form}
								onFinish={onFinish}
								className="searchFormCard"
							>
								{" "}
								<Row
									style={{
										display: "flex",
										// justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<Form.Item
										name="keyWord"
										labelCol={{ span: 24 }}
										className="inputBox"
									>
										<Input
											className="customInputBlog"
											placeholder="Search by Keyword"
										/>
									</Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="applySearchBtn"
									>
										<img src={iconFilter} />
									</Button>
								</Row>
							</Form>
						</div>
						<CustomModal
							isModalOpen={isModalOpen}
							showModal={showModal}
							closeModal={closeModal}
							filterValues={filterValues}
							content={
								<>
									{" "}
									<Form
										onValuesChange={handleValuesChange}
										name="advanced_search"
										className="ant-advanced-search-form"
										form={form}
										onFinish={onFinish}
									>
										<Row>
											<Col span={24}>
												<Form.Item
													name="tournament"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																TOURNAMENT
															</Text>
															<Button
																disabled={
																	!formValues.tournament ||
																	formValues.tournament.length === 0
																}
																className={
																	!formValues.tournament ||
																	formValues.tournament.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("tournament")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Input placeholder="Search"></Input>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="area"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																HOST AREA
															</Text>
															<Button
																disabled={
																	!formValues.area ||
																	formValues.area.length === 0
																}
																className={
																	!formValues.area ||
																	formValues.area.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("area")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														mode="multiple"
														placeholder="Select"
														showSearch
														onChange={(e) => {
															findRegionType(e);
														}}
														onSearch={debounce((value) => {
															const { page, perPage } = areaList;
															effect(NRegion, EGetArea, {
																page,
																perPage,
																area: value,
															});
														}, 300)}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
													>
														{sortData(areaList?.data, "area")?.map(
															(record, index) => {
																return (
																	<Option
																		key={record?.id}
																		value={record?.otherData}
																	>
																		{record?.otherData}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="region"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																HOST REGION
															</Text>
															<Button
																disabled={
																	!formValues.region ||
																	formValues.region.length === 0
																}
																className={
																	!formValues.region ||
																	formValues.region.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("region")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														showArrow
														mode="multiple"
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																?.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
													>
														{sortData(regionList, "region")?.map(
															(vo, index) => (
																<Option key={index} value={vo?.lookupValue}>
																	{vo?.lookupValue}
																</Option>
															)
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																TOURNAMENT TYPE
															</Text>
															<Button
																disabled={
																	!formValues.tournament_type ||
																	formValues.tournament_type.length === 0
																}
																className={
																	!formValues.tournament_type ||
																	formValues.tournament_type.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("tournament_type")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
													name="tournament_type"
												>
													<Select
														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														placeholder="Select"
														mode="multiple"
													>
														{sortData(
															tournamentTypeList?.data,
															"tournament_type"
														)?.map((record, index) => {
															return (
																<Option
																	key={record?.id}
																	value={record?.tournament_type}
																>
																	{record?.tournament_type}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																START DATE
															</Text>
															<Form.Item shouldUpdate>
																{({ getFieldValue }) => {
																	const startDate = getFieldValue("startDate");
																	const endDate = getFieldValue("endDate");
																	const isDisabled = !startDate && !endDate;
																	return (
																		<Button
																			disabled={isDisabled}
																			className={
																				isDisabled ? "resetDisabled" : "reset"
																			}
																			onClick={() => {
																				form.resetFields([
																					"startDate",
																					"endDate",
																				]);
																			}}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	);
																}}
															</Form.Item>
														</div>
													}
												>
													<Row gutter={16}>
														<Col span={12}>
															<Form.Item name="startDate" noStyle>
																<DatePicker
																	format="MMM DD, YYYY"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="Start Date"
																/>
															</Form.Item>
														</Col>
														<Col span={12}>
															<Form.Item name="endDate" noStyle>
																<DatePicker
																	format="MMM DD, YYYY"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="End Date"
																/>
															</Form.Item>
														</Col>
													</Row>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="venue"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																VENUE
															</Text>
															<Button
																disabled={
																	!formValues.venue ||
																	formValues.venue.length === 0
																}
																className={
																	!formValues.venue ||
																	formValues.venue.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("venue")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode.parentNode
														}
														placeholder={"Select"}
														mode="multiple"
													>
														<Option value="Hybrid">Hybrid</Option>
														<Option value="Offline">Offline</Option>
														<Option value="Online">Online</Option>
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="scope"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																SCOPE
															</Text>
															<Button
																disabled={
																	!formValues.scope ||
																	formValues.scope.length === 0
																}
																className={
																	!formValues.scope ||
																	formValues.scope.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("scope")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode.parentNode
														}
														placeholder={"Select"}
														mode="multiple"
														showArrow
													>
														<Option value="Global">Global</Option>
														<Option value="Local">Local</Option>
														<Option value="Regional">Regional</Option>
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="game"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GAME
															</Text>
															<Button
																disabled={
																	!formValues.game ||
																	formValues.game.length === 0
																}
																className={
																	!formValues.game ||
																	formValues.game.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("game")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														showArrow
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchGame(value);
														}, 100)}
													>
														{sortData(gameSearch?.data, "gameEn")?.map(
															(item, index) => {
																return (
																	<Option key={item?.id} value={item?.id}>
																		{item?.gameEn}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PRIZE POOL
															</Text>
															<Button
																disabled={
																	!formValues.prizeMin ||
																	formValues.prizeMin.length === 0 ||
																	!formValues.prizeMax ||
																	formValues.prizeMax.length === 0
																}
																className={
																	!formValues.prizeMin ||
																	formValues.prizeMin.length === 0 ||
																	!formValues.prizeMax ||
																	formValues.prizeMax.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => {
																	resetField("prizeMin");
																	resetField("prizeMax");
																}}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Input.Group compact>
														<Form.Item name="prizeMin" noStyle>
															<InputNumber
																style={{
																	width: "50%",
																	height: "40px",
																	border: "1px solid rgb(187 187 187)",
																	borderRadius: "4px",
																	textAlign: "center",
																	paddingTop: "5px",
																	borderRadius: "8px",
																	boxShadow: "none",
																}}
																placeholder="Min"
																min={0}
																formatter={(v) =>
																	`${v}`.replace(
																		/\d{1,3}(?=(\d{3})+$)/g,
																		(s) => `${s},`
																	)
																}
															/>
														</Form.Item>
														<Form.Item name="prizeMax" noStyle>
															<InputNumber
																style={{
																	width: "50%",
																	height: "40px",
																	border: "1px solid rgb(187 187 187)",
																	borderRadius: "4px",
																	boxShadow: "none",
																	textAlign: "center",
																	borderRadius: "8px",
																	paddingTop: "5px",
																}}
																placeholder="Max"
																min={0}
																formatter={(v) =>
																	`${v}`.replace(
																		/\d{1,3}(?=(\d{3})+$)/g,
																		(s) => `${s},`
																	)
																}
															/>
														</Form.Item>
													</Input.Group>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PLATFORM
															</Text>
															<Button
																disabled={
																	!formValues.platforms ||
																	formValues.platforms.length === 0
																}
																className={
																	!formValues.platforms ||
																	formValues.platforms.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("platforms")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
													name="platforms"
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														mode="multiple"
														showArrow={true}
														placeholder="Select"
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
													>
														{sortData(PlatformData?.data, "platform")?.map(
															(record, index) => {
																return (
																	<Option key={record?.id} value={record?.id}>
																		{record?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="special_characteristics"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																TOURNAMENT CHARACTERISTICS
															</Text>
															<Button
																disabled={
																	!formValues.special_characteristics ||
																	formValues.special_characteristics.length ===
																		0
																}
																className={
																	!formValues.special_characteristics ||
																	formValues.special_characteristics.length ===
																		0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() =>
																	resetField("special_characteristics")
																}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow={true}
														placeholder="Select"
														mode="multiple"
													>
														{sortData(
															specialCharacteristicsList?.data,
															"tournament_special_characterisctics"
														)?.map((record, index) => {
															return (
																<Option
																	key={record?.id}
																	value={
																		record?.tournament_special_characterisctics
																	}
																>
																	{record?.tournament_special_characterisctics}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="hosts"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																HOST
															</Text>
															<Button
																disabled={
																	!formValues.hosts ||
																	formValues.hosts.length === 0
																}
																className={
																	!formValues.hosts ||
																	formValues.hosts.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("hosts")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														showArrow
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchCompany(value);
														}, 300)}
													>
														{sortData(organizationSearch?.data)?.map(
															(item, index) => {
																return (
																	<Option key={item?.id} value={item?.id}>
																		{item?.name}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24} style={{ textAlign: "right" }}>
												<Space>
													<Button
														type="primary"
														htmlType="submit"
														className="applyBtn"
													>
														Apply Now
													</Button>
													<Button
														type="default"
														onClick={onReset}
														className="resetBtn"
													>
														Reset All
													</Button>
												</Space>
											</Col>
										</Row>
									</Form>
								</>
							}
						/>
					</div>

					<Spin spinning={loading} size="large">
						<div className="CompanyBox pdb40">
							<Row className="RowBox mt30">
								<Col span={24}>
									{!isMobile ? (
										<Table
											locale={{ emptyText: CustomEmpty }}
											scroll={{ x: "max-content" }}
											dataSource={TournamentList?.data || []}
											columns={columns}
											className="CompanyTableBox"
											pagination={false} // Hide pagination
											onChange={(pagination, filters, sort) => {
												const pageSize = TournamentList?.perPage || 10; // Get the pageSize from your data or default
												const currentPage = TournamentList?.page || 1; // Get the current page from your data or default

												setSortState(sort);
												effect(NTournament, EGet, {
													...keyword,
													perPage: pageSize,
													page: currentPage,
													field: sort?.column && sort?.field,
													isDesc:
														(sort?.column &&
															sort?.order &&
															sortType[sort?.order]) ||
														null,
												});
												sessionStorage.setItem("orgPage", pagination?.page);
												sessionStorage.setItem(
													"orgPageSize",
													pagination?.pageSize
												);
											}}
										/>
									) : (
										<Collapse
											accordion
											activeKey={activeKey}
											onChange={handleCollapseChange}
											className="MobileList"
											expandIconPosition="right"
											expandIcon={({ isActive }) => (
												<div className="custom-arrow">
													<img
														src={isActive ? reverseArrow : iconArrow}
														alt="arrow"
													/>
												</div>
											)}
										>
											{TournamentList?.data.map((item) => (
												<Panel
													header={
														<div className="panel-header">
															{item?.img && (
																<img
																	src={item.imageSrc} // Assuming item has an imageSrc property
																	alt="tournament"
																	className="tournament-image"
																/>
															)}
															<a
																className="a_style"
																href={`#/esports/details/${item?.id}`}
															>
																{item?.tournament}
															</a>
														</div>
													}
													key={item.id}
												>
													<p>
														<Row>
															<Col className="head" span={12}>
																Host Area/Region
															</Col>
															<Col className="text" span={12}>
																{item?.area?.length
																	? item?.area?.join(", ")
																	: "-"}
																<br />
																{item?.region?.length
																	? item?.region?.join(", ")
																	: "-"}
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Game
															</Col>
															<Col span={12}>
																{item?.withGameList?.map((value, index) => {
																	return (
																		<Tag
																			className="greenTag"
																			key={index}
																			onClick={() => {
																				history.push(
																					`/games_organizations/games/details/${value?.id}`
																				);
																			}}
																		>
																			{value?.gameEn + "\n"}
																		</Tag>
																	);
																})}
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Prize Pool
															</Col>
															<Col className="text" span={12}>
																<>
																	{item?.prizePool
																		? "$" +
																		  item?.prizePool
																				?.toString()
																				?.replace(/(\d)(?=(\d{3})+\b)/g, "$1,")
																		: "-"}
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Platforms
															</Col>
															<Col className="text" span={12}>
																<>
																	{item?.platforms &&
																		item?.platforms?.map((item, index) => {
																			return (
																				<span key={index}>{item + "\n"}</span>
																			);
																		})}
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Scope
															</Col>
															<Col className="text" span={12}>
																<>
																	<div className="Tabel_Company">
																		<span>{item?.scope || "-"}</span>
																	</div>
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Venue
															</Col>
															<Col className="text" span={12}>
																<>
																	<div className="Tabel_Company">
																		<span>{item?.venue || "-"}</span>
																	</div>
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Start Date
															</Col>
															<Col className="text" span={12}>
																{item?.startDate
																	? formatDate(new Date(item.startDate))
																	: ""}
															</Col>
														</Row>
													</p>
												</Panel>
											))}
										</Collapse>
									)}
								</Col>
							</Row>
							<Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
								<Col span={24}>
									{TournamentList?.error != 1009 && TournamentList?.total
										? TournamentList?.total && (
												<Pagination
													current={
														parseInt(TournamentList && TournamentList?.page) ||
														1
													}
													total={(TournamentList && TournamentList?.total) || 0}
													pageSize={TournamentList && TournamentList?.perPage}
													onChange={onPageChange}
													showSizeChanger
													showQuickJumper
													showTotal={(total, range) =>
														`${range[0]}-${range[1]} of ${total} items`
													}
													locale={paginationLocale}
													itemRender={(page, type, originalElement) => {
														if (type === "page") {
															return <span>{page}</span>;
														}
														return originalElement;
													}}
													className="custom-pagination"
												/>
										  )
										: ""}
								</Col>
							</Row>
						</div>
					</Spin>
				</>
			) : (
				customizeRenderEmpty()
			)}
		</div>
	);
};
