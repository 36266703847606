import React, { useEffect, useState } from "react";
import { Row, Modal, Button, Input, Col, Form } from "antd";
export default (props) => {
	const [editForm] = Form.useForm();
	const { onOk, visible, onCancel } = props;
	useEffect(() => {
		editForm.resetFields();
	}, [visible]);
	const onOks = async () => {
		try {
			await editForm.validateFields();
			let values = editForm.getFieldValue();
			onOk && onOk(values);
		} catch (e) {}
	};
	return (
		<Modal {...props}>
			<Form className="newBox" form={editForm} name="NewUser">
				<Row>
					<Col span={12}>
						<p>Username</p>
						<Form.Item
							name="username"
							rules={[{ required: true, message: "Please input username!" }]}
						>
							<Input
								placeholder="Input here Username"
								suffix={
									<img
										style={{ width: "16px" }}
										src={
											require("@/assets/images/login/ico_userName.png")?.default
										}
									/>
								}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<p>Email</p>
						<Form.Item
							name="email"
							rules={[
								{ required: true },
								{
									type: "email",
									message: "The input is not valid E-mail!",
								},
							]}
						>
							<Input
								placeholder="Input here Email"
								suffix={
									<img
										style={{ width: "16px" }}
										src={require("@/assets/images/login/ico_sms.png")?.default}
									/>
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<p>Password</p>
						<Form.Item
							name="password"
							rules={[{ required: true, message: "Please input Password!" }]}
						>
							<Input.Password
								placeholder="Input here Password"
								iconRender={(visible) => (
									<img
										style={{ width: "15px" }}
										src={require("@/assets/images/login/ico_lock.png")?.default}
									/>
								)}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<p>Company Name</p>
						<Form.Item
							name="company"
							rules={[
								{ required: true, message: "Please input Company Name!" },
							]}
						>
							<Input
								placeholder="Input here Company Name"
								suffix={
									<img
										style={{ width: "16px" }}
										src={
											require("@/assets/images/login/ico_company.png")?.default
										}
									/>
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<p>Phone Number</p>
						<Form.Item name="phone">
							<Input
								placeholder="Input here Phone Number"
								suffix={
									<img
										style={{ width: "14px" }}
										src={
											require("@/assets/images/login/ico_phone.png")?.default
										}
									/>
								}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<p>Contacts</p>
						<Form.Item
							name="contact"
							rules={[{ required: true, message: "Please input Constacts!" }]}
						>
							<Input
								placeholder="Input here Constacts"
								suffix={
									<img
										style={{ width: "16px" }}
										src={
											require("@/assets/images/login/ico_contact.png")?.default
										}
									/>
								}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Row style={{ marginTop: 28 }}>
				<Col style={{ margin: "0 auto" }}>
					<Button
						className="btn_1"
						onClick={() => {
							onCancel && onCancel();
						}}
					>
						Cancel
					</Button>
					<Button
						className="btn_2"
						style={{ marginLeft: 16 }}
						htmlType="submit"
						onClick={() => {
							onOks();
						}}
					>
						Confirm registration
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
