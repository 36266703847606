import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "antd";
import iconFilter from "../assets/icon/icon_filter.png";
import "./modal.less";
const App = ({
	filterValues,
	content,
	btnCustom = null,
	modalCustom = null,
	isModalOpen,
	showModal,
	closeModal,
}) => {
	const [isMobile, setIsMobile] = useState(false);
	const buttonRef = useRef(null);

	const handleOk = () => {
		closeModal();
	};

	const handleCancel = () => {
		closeModal();
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	useEffect(() => {
		// Calculate scrollbar width
		const scrollbarWidth =
			(window.innerWidth - document.documentElement.clientWidth) / 0.75; // Adjust for zoom level
		document.documentElement.style.setProperty(
			"--scrollbar-width",
			`${scrollbarWidth}px`
		);

		if (isModalOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}

		// Cleanup function to remove the class on unmount
		return () => {
			document.body.classList.remove("no-scroll");
			document.documentElement.style.removeProperty("--scrollbar-width"); // Clean up
		};
	}, [isModalOpen, isMobile]);

	const modalWidth = 280;

	return (
		<>
			<Button
				type="primary"
				onClick={showModal}
				ref={buttonRef}
				className={
					btnCustom
						? btnCustom
						: isModalOpen || filterValues?.length > 0
						? "filterBtnSelected"
						: "filterresetBtn"
				}
			>
				<img
					style={{ marginRight: "5px" }}
					src={iconFilter}
					alt="Filter Icon"
				/>
				Filter
			</Button>
			{isModalOpen && (
				<Modal
					open={true}
					onOk={handleOk}
					onCancel={handleCancel}
					getContainer={false}
					footer={null}
					header={null}
					className={modalCustom ? modalCustom : "filter-modal"}
					style={{
						position: "absolute",
						top: buttonRef.current.getBoundingClientRect().bottom / 0.75 + 15,
						left:
							(buttonRef.current.getBoundingClientRect().left - modalWidth) /
							0.75,
						width: "fit-content",
						maxWidth: "480px",
					}}
					mask={isMobile}
					maskClosable={true}
				>
					{content}
				</Modal>
			)}
		</>
	);
};

export default App;
