import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import iconFilter from "@/assets/icon/icon_search.png";
import CustomModal from "../../../components/modal";
import { SearchOutlined } from "@ant-design/icons";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Tag,
	Form,
	Input,
	DatePicker,
	Select,
	Pagination,
	Empty,
	Spin,
	Typography,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	EGetType,
	NBlog,
	NCompany,
	NGame,
	NGenre,
	NPlatform,
	NRegion,
	EGetArea,
	NInsightType,
	EGetTypeSelect,
	NOrganizationMulti,
	EGetAll,
	NLogin,
	EGetRegion,
} from "../../../models/contants";
import { customizeRenderEmpty } from "../../../utils/utils";
import formatDate from "@/utils/dateFormater";
import imgSource from "../../../assets";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default (props) => {
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const [form] = Form.useForm();
	const { BlogList, loading } = useStore(NBlog);
	const { OrganizationAll } = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const { GenreList } = useStore(NGenre);
	const { PlatformData, TypeList } = useStore(NPlatform);
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const { TypeSelectlist } = useStore(NInsightType);
	const { history } = props;
	const [filterValues, setFilterValues] = useState([]);
	const { Text } = Typography;
	const {
		location: { state },
	} = history;
	const [searchValue, setSeachValue] = useState({});
	const [bigType, setBigType] = useState(null);
	const [showAllTags, setShowAllTags] = useState({});

	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	useEffect(() => {
		effect(NLogin, EGet, {});
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NCompany, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGetType, {});
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NGame, EGet, { page: 1, perPage: 10, id: state?.game });
		effect(NRegion, EGet, { page: 1, perPage: 10 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NInsightType, EGetTypeSelect, { page: 1, perPage: 50 });
		effect(NOrganizationMulti, EGetAll, {
			page: 1,
			perPage: 10,
			id: state?.company,
		});
		sessionStorage.removeItem("newsPage");
		sessionStorage.removeItem("newsLastPage");
		sessionStorage.removeItem("newsPerPage");
		sessionStorage.removeItem("newsArr");
		sessionStorage.removeItem("newsIndex");
		return () => {};
	}, []);
	const paginationLocale = {
		items_per_page: "per page",
	};
	useEffect(() => {
		let page = sessionStorage.getItem("blogClientPage");
		let pageSize = sessionStorage.getItem("blogClientPageSize");
		if (state) {
			form.setFieldsValue({
				...state,
			});
			setSeachValue({ ...searchValue, ...state });
		}
		if (page && pageSize) {
			effect(NBlog, EGet, { page, pageSize });
		} else {
			effect(NBlog, EGet, { page: 1, perPage: 10, ...state });
		}
	}, []);

	// const onPageChange = (page, pageSize) => {
	// 	effect(NBlog, EGet, { page, perPage: 10 });
	// 	sessionStorage.setItem("blogClientPage", page);
	// 	sessionStorage.setItem("blogClientPageSize", pageSize);
	// };
	const [sortState, setSortState] = useState(null);
	const onPageChange = (page, pageSize, sort) => {
		if (page && pageSize) {
			effect(NBlog, EGet, {
				...searchValue,
				page: page,
				perPage: pageSize,
				field: sortState && sortState?.field,
				isDesc: (sortState && sortState?.order) || null,
			});
			sessionStorage.setItem("blogClientPage", page);
			sessionStorage.setItem("blogClientPageSize", pageSize);
		}
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetAll, { name: value, page: 1, perPage: 10 });
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		// if (values.date) {
		values.startDate = values?.startDate
			? moment(values?.startDate?._d)?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.endDate
			? moment(values?.endDate?._d)?.format("YYYY-MM-DD")
			: null;
		// }
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		setFilterValues(valuesArray);
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];
		// values.platform = PlatformData?.data?.find(
		// 	(item) => item.lookupValue === values?.platform
		// )?.id;
		closeModal();
		delete values?.date;
		effect(NBlog, EGet, { page: 1, perPage: 10, ...values });
	};

	const toDetails = (value) => {
		const index = BlogList?.data?.findIndex((item) => item?.id === value);
		history.push(`/news_articles/blog_posts/details/${value}`);

		sessionStorage.setItem("newsPage", BlogList?.page);
		sessionStorage.setItem("newsLastPage", BlogList?.lastPage);
		sessionStorage.setItem("newsPerPage", BlogList?.perPage);
		sessionStorage.setItem("newsArr", JSON.stringify(BlogList?.data));
		sessionStorage.setItem("newsIndex", index);
	};

	const onReset = () => {
		sessionStorage.setItem("blogClientPage", 1);
		setFormValues([]);
		sessionStorage.setItem("blogClientPageSize", 10);
		setBigType(null);
		form.resetFields();
		setFilterValues([]);
		closeModal();
		effect(NBlog, EGet, { page: 1, perPage: 10 });
	};
	const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];
		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});
		setRegionArr(Array.from(new Set(updatedRegionArr)));
		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
		setFormValues((prevValues) => ({
			...prevValues,
			["region"]: Array.from(new Set(updatedRegionArr)),
		}));
	}
	const handleTypeSearch = (e) => {
		setBigType(e);
		effect(NInsightType, EGetTypeSelect, { page: 1, BigType: e });
	};
	function removeItalicsMarkdown(text) {
		let result = text
			.normalize("NFKD")
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		return result;
	}
	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "area") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	const searchByTags = (state) => {
		effect(NBlog, EGet, { page: 1, perPage: 10, ...state });
		if (state) {
			form.setFieldsValue({
				...state,
			});
		}
	};
	return (
		<>
			<div>
				<p className="mainheader">Blog Posts</p>
				<div className="subHeader">
					Filter the knowledge base for Blog Post data.
				</div>
			</div>
			{userInfo?.type == "Admin" ||
			(currentSubscribe?.[0]?.module.hasOwnProperty("BlogPosts") &&
				currentSubscribe?.[0]?.module["BlogPosts"]?.length > 0) ? (
				<div>
					<div className="searchBlog" style={{}}>
						<div style={{ display: "flex" }}>
							<Form
								onValuesChange={handleValuesChange}
								form={form}
								onFinish={onFinish}
								className="searchFormCard"
							>
								{" "}
								<Row
									style={{
										width: "100%",
										display: "flex",

										alignItems: "center",
									}}
								>
									<Form.Item
										name="keyWord"
										// labelCol={{ span: 24 }}
										className="inputBox"
									>
										<Input
											className="customInputBlog"
											placeholder="Search by Keyword"
										/>
									</Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="applySearchBtn"
									>
										<img src={iconFilter} />
									</Button>
								</Row>
							</Form>
						</div>
						<CustomModal
							isModalOpen={isModalOpen}
							showModal={showModal}
							closeModal={closeModal}
							filterValues={filterValues}
							content={
								<>
									{" "}
									<Form
										onValuesChange={handleValuesChange}
										name="advanced_search"
										className="ant-advanced-search-form"
										form={form}
										onFinish={onFinish}
									>
										<Row>
											<Col span={24}>
												<Form.Item
													name="area"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																AREA
															</Text>
															<Button
																disabled={
																	!formValues.area ||
																	formValues.area.length === 0
																}
																className={
																	!formValues.area ||
																	formValues.area.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("area")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														mode="multiple"
														placeholder="Select"
														showSearch
														onChange={(e) => {
															findRegionType(e);
														}}
														onSearch={debounce((value) => {
															const { page, perPage } = areaList;
															effect(NRegion, EGetArea, {
																page,
																perPage,
																area: value,
															});
														}, 300)}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
													>
														{sortData(areaList?.data, "area")?.map(
															(record, index) => {
																return (
																	<Option
																		key={record?.id}
																		value={record?.otherData}
																	>
																		{record?.otherData}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="region"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																REGION
															</Text>
															<Button
																disabled={
																	!formValues.region ||
																	formValues.region?.length === 0
																}
																className={
																	!formValues.region ||
																	formValues.region?.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("region")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														mode="multiple"
														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																?.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"

														// onSearch={debounce((value) => {
														// 	const { page, perPage } = areaList;
														// 	effect(NRegionArea, EGetRegion, {
														// 		page,
														// 		perPage,
														// 		region: value,
														// 	});
														// }, 300)}
													>
														{sortData(regionList, "region")?.map(
															(vo, index) => (
																<Option key={index} value={vo?.lookupValue}>
																	{vo?.lookupValue}
																</Option>
															)
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="keyWord"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																KEYWORD SEARCH
															</Text>
															<Button
																disabled={
																	!formValues.keyWord ||
																	formValues.keyWord.length === 0
																}
																className={
																	!formValues.keyWord ||
																	formValues.keyWord.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("keyWord")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Input
														className="customInputBlog"
														placeholder="Search"
														autoComplete="off"
														prefix={<SearchOutlined />}
													/>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PUBLISHED DATE
															</Text>
															<Form.Item shouldUpdate>
																{({ getFieldValue }) => {
																	const startDate = getFieldValue("startDate");
																	const endDate = getFieldValue("endDate");
																	const isDisabled = !startDate && !endDate;
																	return (
																		<Button
																			disabled={isDisabled}
																			className={
																				isDisabled ? "resetDisabled" : "reset"
																			}
																			onClick={() => {
																				form.resetFields([
																					"startDate",
																					"endDate",
																				]);
																			}}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	);
																}}
															</Form.Item>
														</div>
													}
												>
													<Row gutter={16}>
														<Col span={12}>
															<Form.Item name="startDate" noStyle>
																<DatePicker
																	format="MMM DD, YYYY"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="Start Date"
																/>
															</Form.Item>
														</Col>
														<Col span={12}>
															<Form.Item name="endDate" noStyle>
																<DatePicker
																	format="MMM DD, YYYY"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="End Date"
																/>
															</Form.Item>
														</Col>
													</Row>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="type"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																CATEGORY
															</Text>
															<Button
																disabled={
																	!formValues.type ||
																	formValues.type.length === 0
																}
																className={
																	!formValues.type ||
																	formValues.type.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("type")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														onChange={(value) => {
															handleTypeSearch(value);
														}}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
													>
														{sortData(TypeList?.mianType, "type")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.type}>
																		{item?.type}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="subType"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																SUB-CATEGORY
															</Text>
															<Button
																disabled={
																	!formValues.subType ||
																	formValues.subType.length === 0
																}
																className={
																	!formValues.subType ||
																	formValues.subType.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("subType")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														showSearch
														placeholder="Select"
														disabled={bigType ? false : true}
													>
														{sortData(TypeSelectlist?.subType, "type")?.map(
															(item, index) => (
																<Option
																	key={item?.id || index}
																	value={item?.subType}
																>
																	{item?.subType}
																</Option>
															)
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="game"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GAME
															</Text>
															<Button
																disabled={
																	!formValues.game ||
																	formValues.game.length === 0
																}
																className={
																	!formValues.game ||
																	formValues.game.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("game")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														showSearch
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchGame(removeItalicsMarkdown(value));
														}, 100)}
													>
														{gameSearch &&
															sortData(gameSearch?.data, "gameEn")?.map(
																(record, index) => {
																	return (
																		<Option value={record?.id} key={index}>
																			{record?.gameEn}
																		</Option>
																	);
																}
															)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="company"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																ORGANIZATION
															</Text>
															<Button
																disabled={
																	!formValues.company ||
																	formValues.company.length === 0
																}
																className={
																	!formValues.company ||
																	formValues.company.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("company")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														showSearch
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchCompany(value);
														}, 300)}
													>
														{sortData(OrganizationAll?.data)?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.name}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="genres"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GENRE
															</Text>
															<Button
																disabled={
																	!formValues.genres ||
																	formValues.genres.length === 0
																}
																className={
																	!formValues.genres ||
																	formValues.genres.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("genres")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														showSearch
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchGenre(value);
														}, 300)}
													>
														{sortData(GenreList?.data, "genre")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.genre}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="platform"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PLATFORM
															</Text>
															<Button
																disabled={
																	!formValues.platform ||
																	formValues.platform.length === 0
																}
																className={
																	!formValues.platform ||
																	formValues.platform.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("platform")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														className="select"
														mode="multiple"
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														showSearch
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchPlatform(value);
														}, 300)}
													>
														{sortData(PlatformData?.data, "platform")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.lookupValue}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24} style={{ textAlign: "right" }}>
												<Space>
													<Button
														type="primary"
														htmlType="submit"
														className="applyBtn"
													>
														Apply Now
													</Button>
													<Button
														type="default"
														onClick={onReset}
														className="resetBtn"
													>
														Reset All
													</Button>
												</Space>
											</Col>
										</Row>
									</Form>
								</>
							}
						/>
					</div>

					<Spin spinning={loading} size="large">
						<div className="BlogBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									<ul className="ProlistBox">
										{/* {currentSubscribe?.[0]?.module["BlogPosts"]?.length > 0 ? (
											BlogList?.error == 1009 ||
											BlogList?.total > 0 || <Empty />
										) : (
											<></>
										)} */}

										{BlogList?.data.length > 0 ? (
											BlogList?.data?.map((item, index) => {
												const date = new Date(item?.date);
												const formattedDate = formatDate(date);
												const showAll = showAllTags[index] || false;
												return (
													<li key={index}>
														<div
															className="insight_list_cont"
															style={{ width: "100%" }}
														>
															<h3>
																<div>
																	<div
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			toDetails(item?.id);
																		}}
																	>
																		{item?.title}
																	</div>
																</div>
																<div className="insight_list_date_style">
																	{formattedDate}
																</div>
															</h3>
															<div className="areaRegionBox">
																{item?.area?.length > 0 && (
																	<span
																		className="listLink"
																		onClick={() => {
																			const state = {
																				area: item?.area,
																			};
																			searchByTags(state);
																		}}
																	>
																		<img
																			src={imgSource?.map_icon}
																			style={{
																				marginRight: "5px",
																				width: "24px",
																			}}
																		></img>
																		{item?.area?.length > 0 &&
																			item?.area?.join(", ")}
																	</span>
																)}
																{item?.region?.length > 0 && (
																	<span
																		className="listLink"
																		onClick={() => {
																			const state = {
																				region: item?.region,
																			};
																			searchByTags(state);
																		}}
																	>
																		<img
																			src={imgSource?.globe_gray_icon}
																			style={{
																				marginRight: "5px",
																				width: "24px",
																			}}
																		></img>
																		{item?.region?.length > 0 &&
																			item?.region?.join(", ")}
																	</span>
																)}
																{item?.type && (
																	<span
																		className="listLink"
																		onClick={() => {
																			const state = {
																				type: item?.type,
																			};
																			searchByTags(state);
																		}}
																	>
																		<img
																			src={imgSource?.folder_icon}
																			style={{
																				marginRight: "5px",
																				width: "24px",
																			}}
																		></img>
																		{item?.type}
																	</span>
																)}
															</div>
															<div className="Tag_List_Box">
																{item?.withGames
																	?.slice(
																		0,
																		showAll ? item?.withGames?.length : 3
																	)
																	?.map((vi, iii) => (
																		<span
																			key={iii}
																			className="game-title"
																			onClick={() => {
																				history.push(
																					`/games_organizations/games/details/${vi?.id}`
																				);
																			}}
																		>
																			{vi?.gameEn}
																		</span>
																	))}
																{item?.withCompanys
																	?.slice(
																		0,
																		showAll ? item?.withCompanys?.length : 3
																	)
																	?.map((vi, ii) => (
																		<span
																			key={ii}
																			className="org-title"
																			onClick={() => {
																				history.push(
																					`/games_organizations/organizations/details/${vi?.id}`
																				);
																			}}
																		>
																			{vi?.name}
																		</span>
																	))}

																{(item?.withCompanys?.length > 3 ||
																	item?.withGames?.length > 3) && (
																	<span
																		className="show-all"
																		onClick={() => toggleShowAll(index)}
																	>
																		{showAll ? "Show less..." : "Show all..."}
																	</span>
																)}
															</div>
															<div
																className="insght_lst_info"
																onClick={() => {
																	toDetails(item?.id);
																}}
															>
																{(() => {
																	const content = item?.description?.replace(
																		/<[^>]+>/g,
																		""
																	);
																	console.log(content);
																	const words = content?.split(" ");

																	if (words?.length > 50) {
																		return (
																			words?.slice(0, 50)?.join(" ") + "..."
																		);
																	} else {
																		return content;
																	}
																})()}
															</div>
														</div>
													</li>
												);
											})
										) : (
											<Empty />
										)}
									</ul>
									{/* </div> */}
								</Col>

								<Row style={{ padding: "0 10px 0 10px", width: "100%" }}>
									<Col span={24}>
										{BlogList?.error != 1009 && BlogList?.total
											? BlogList?.total && (
													<Pagination
														current={parseInt(BlogList && BlogList?.page) || 1}
														total={(BlogList && BlogList?.total) || 0}
														pageSize={BlogList && BlogList?.perPage}
														onChange={onPageChange}
														showSizeChanger
														showQuickJumper
														showTotal={(total, range) =>
															`${range[0]}-${range[1]} of ${total} items`
														}
														locale={paginationLocale}
														itemRender={(page, type, originalElement) => {
															if (type === "page") {
																return <span>{page}</span>;
															}
															return originalElement;
														}}
														className="custom-pagination"
													/>
											  )
											: ""}
									</Col>
								</Row>
							</Row>
						</div>
					</Spin>
				</div>
			) : (
				customizeRenderEmpty()
			)}
		</>
	);
};
