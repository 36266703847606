import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.less";
import reportWebVitals from "./reportWebVitals";
import { notification } from "antd";
import { initDva, initRequest, bindJWTToken } from "./utils/dva16";
import models from "./models";
import { Config } from "./config";
import { getToken, setToken } from "./utils/utils";
console.log("lllllllllllllllllllllllllllllll");
initDva(models, { useImmer: false, printLog: false });
initRequest(Config.SERVER_HOME, (status, data) => {

	console.log("[error]", status, data);
	if (status === 401 || data?.code === 501) {
		window.location.href = "#/login";
		console.log("status: ", status);
		if (getToken() === "" || getToken() === null || getToken() === undefined) {
			window.location.href = "#/login";
		}
		return;
	}

	notification.error({
		duration:5,
		message: (
			<span style={{ fontSize: "1rem", color: "white", marginLeft: "-30px" }}>
				{data?.message ||
					"We encountered an issue while processing your request."}
			</span>
		),
		description: "",
		placement: "bottom",
		style: {
			width: "100%",
			fontWeight: "bold",
			background: "#EE5457",
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			position: "relative", // To allow absolute positioning of the close icon
		},
		icon: (
			<i
				className="anticon anticon-check-circle"
				style={{ fontSize: "0", color: "white", display: "none" }}
			/>
		),
		closeIcon: (
			<span
				style={{
					color: "white",
					fontSize: "1.25rem",
					position: "absolute", // Absolute positioning
					top: -8, // Adjust top position
					right: -10, // Adjust right position
					cursor: "pointer", // Make it clickable
				}}
			>
				x
			</span>
		),
	});
});
bindJWTToken(getToken());

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
