import React from "react";
import { Layout, Typography } from "antd";
import "./index.less";
import { useMediaQuery } from "react-responsive";
import headerImg from "../../assets/header.png";
const { Content } = Layout;
const { Title, Paragraph, Link } = Typography;

const TermsOfUse = (props) => {
	const isSmallScreen = useMediaQuery({ maxWidth: 768 });
	const { history } = props;

	return (
		<Layout className="container">
			<header className="header">
				<div onClick={() => history.push("/")}>
					<img src={headerImg} className="headerimg" />
				</div>
			</header>
			<Content className="content">
				<Typography>
					<Title
						level={2}
						className="pageTitle"
						style={{ fontSize: isSmallScreen ? "2rem" : "3rem" }}
					>
						Subscriber's Terms of Use Agreement
					</Title>
					<Title className="subTitle">January 1, 2024</Title>
					<Title className="subTitle">Niko Partners, Inc.</Title>

					<Paragraph>
						This Subscriber’s Terms of Use Agreement (the “Agreement”) provides
						the terms and conditions upon which you as well as any and all users
						that access our Service through your company account (collectively
						referred to as “you”, or “Subscriber” herein) agree to grant to Niko
						Partners, a California corporation (hereinafter referred to as “we”,
						“our”, “us” or “Niko”), certain rights with respect to your account
						as a Subscriber of our data, analysis and content on our website
						(including any subdomains) located at https://iq.nikopartners.com
						(the “Website”). This Agreement incorporates by reference herein
						Privacy Policy located at https://iq.nikopartners.com.
					</Paragraph>

					<Title level={2} className="subTitle">
						Terms of Use
					</Title>

					<Title level={3} className="subTitle">
						1. Definitions
					</Title>
					<Paragraph>
						<strong>Administrator:</strong> means any employee, administrator,
						or other representative who enters into this Agreement on behalf of
						a corporation or other legal entity.
						<br />
						<strong>Niko IP:</strong> means all of Niko's intellectual property
						and proprietary technology.
						<br />
						<strong>Effective Date:</strong> means the earlier of the date this
						Agreement is accepted by your Administrator or the date you begin
						using the Service.
						<br />
						<strong>Intellectual Property Rights:</strong> means inventions,
						patent applications, patents, design rights, copyrights, trademarks,
						service marks, trade names, domain name rights, know-how and other
						trade secrets.
						<br />
						<strong>Pricing Proposal:</strong> means the pricing proposal
						delivered to you by Niko prior to your entering into this Agreement.
						<br />
						<strong>Service:</strong> means Niko’s regular business intelligence
						reports and any other services identified during the ordering
						process.
						<br />
						<strong>Subscribers:</strong> means you, your employees, and others
						who are authorized to use the Service.
					</Paragraph>

					<Title level={3} className="subTitle">
						2. License Grant
					</Title>
					<Paragraph>
						Subject to the terms and conditions of this Agreement, Niko hereby
						grants to you a non-exclusive, non-transferable, worldwide right to
						use the Service, solely for your own internal business purposes and
						within your organization, subject to the terms and conditions of
						this Agreement.
					</Paragraph>

					<Title level={3} className="subTitle">
						3. Restrictions
					</Title>
					<Paragraph>
						You may not share, distribute, publicly display, publish, or
						reproduce any deliverables or materials (or any portion thereof)
						tendered to you in connection with the Service to anyone outside of
						your organization, unless you obtain express written consent in
						advance from Niko. Niko hereby grants you the right to use the
						Service for the applicable periods for which subscription fees have
						been paid.
					</Paragraph>

					<Title level={3} className="subTitle">
						4. Your Responsibilities
					</Title>
					<Paragraph>
						You are responsible for all activity occurring under your Subscriber
						accounts. You agree to (i) notify Niko immediately of any
						unauthorized use of any password or account or any other known or
						suspected breach of security; (ii) report to Niko immediately and
						use reasonable efforts to stop immediately any copying or
						distribution of the Service that is known or suspected by you or
						your Subscribers; (iii) not impersonate another Subscriber or
						provide false identity information to gain access to or use of the
						Service; and (iv) comply with applicable laws and regulations with
						respect to your use of the Service.
					</Paragraph>

					<Title level={3} className="subTitle">
						5. Ownership of Intellectual Property Rights
					</Title>
					<Paragraph>
						Niko alone (and its licensors, where applicable) will own all right,
						title and interest, including all related Intellectual Property
						Rights, in and to the Niko IP and the Service.
					</Paragraph>
					<Paragraph>
						All contents of the Website and the Service including but not
						limited to design, text, software, technical drawings,
						configurations, graphics, other files, and their selection and
						arrangement are: Copyright © Niko, and/or the proprietary property
						of its suppliers, affiliates, or licensors. All Rights Reserved.
					</Paragraph>
					<Paragraph>
						Niko and the Niko logo are without limitation, either trademarks,
						service marks or registered trademarks of Niko Partners, Inc., and
						may not be copied, imitated, or used, in whole or in part, without
						Niko's prior written permission.
					</Paragraph>

					<Title level={3} className="subTitle">
						6. Fees and Payment
					</Title>
					<Paragraph>
						(a) Payment. You agree that you will pay all fees or charges in
						accordance with Niko’s fees, charges, and billing terms in effect at
						the time a fee or charge is due and payable. You are responsible for
						paying for all the Service for the entire period subscribed, whether
						or not the Service is actively used.
					</Paragraph>
					<Paragraph>
						Niko's fees are exclusive of all taxes, levies, or duties imposed by
						taxing authorities, and you will be responsible for payment of all
						such taxes, levies, or duties, excluding only United States (federal
						or state) income taxes on Niko.
					</Paragraph>
					<Paragraph>
						(c) Change of Information. You agree to provide Niko with complete
						and accurate billing and contact information, including your
						organization’s legal name, street address, e-mail address, and
						telephone number. You agree to update this information within ten
						(10) days after any change.
					</Paragraph>
					<Paragraph>
						(d) Non-Payment and Suspension. Niko reserves the right to suspend
						or terminate this Agreement and your access to the Service if your
						account becomes delinquent. You will continue to be charged for
						subscription fees during any period of suspension. If you or Niko
						initiates termination of this Agreement, you will be obligated to
						pay the computed balance due on your account as prescribed above.
						You agree that Niko may invoice you for such unpaid fees. Niko
						reserves the right to impose a reconnection fee if your account is
						suspended and you thereafter request access to the Service.
					</Paragraph>

					<Title level={3} className="subTitle">
						7. Representations and Warranties
					</Title>
					<Paragraph>
						Each party represents and warrants that it has the legal power and
						authority to enter into this Agreement. You represent and warrant
						that you have not falsely identified yourself nor provided any false
						information to gain access to the Service, and that all billing
						information you provided is correct.
					</Paragraph>

					<Title level={3} className="subTitle">
						8. Indemnification; Infringement
					</Title>
					<Paragraph>
						You agree to indemnify and hold Niko, its licensors and each such
						party's parent organizations, subsidiaries, affiliates, officers,
						directors, employees, attorneys and agents (collective,
						“Indemnitees”) harmless from and against any and all claims, costs,
						damages, losses, liabilities and expenses (including attorneys' fees
						and costs) arising out of or in connection with any claim (i) by any
						third party that use of the Subscriber Data infringes the rights of,
						or has caused harm to, a third party, or (ii) based on any breach of
						your representations and warranties made herein or any breach by you
						or your Subscribers of this Agreement.
					</Paragraph>
					<Paragraph>
						Niko will have no indemnification obligation, and you will indemnify
						Niko pursuant to this Agreement, for claims arising from any
						infringement arising from the combination of the Service with any of
						your products, service, hardware or business processes.
					</Paragraph>
					<Paragraph>
						Your obligation to indemnify the Indemnities is conditioned on the
						party seeking indemnification (i) providing you with written notice
						of the claim (ii) giving you sole control of the defense and
						settlement of the claim (provided that you may not settle or defend
						any claim unless it unconditionally releases the party seeking
						indemnification of all liability), (iii) providing you with material
						available information and reasonable assistance, and (iv) not having
						compromised or settled such claim.
					</Paragraph>
					<Paragraph>
						If Niko determines that the Service is likely to infringe any
						patent, trademark or copyright or misappropriate the trade secret of
						a third party, Niko may at its option: (i) procure at no cost to you
						the right to continue to use the Service and Software in accordance
						with this Agreement; or (ii) replace or modify the Service or the
						Software so that it no longer infringes or misappropriates, and
						thereafter substantially conforms to the requirements hereof. This
						Section 8 states Niko's entire liability to you for any infringement
						of any third party rights.
					</Paragraph>

					<Title level={3} className="subTitle">
						9. Disclaimer of Warranties
					</Title>
					<Paragraph>
						NIKO AND ITS LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY
						AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY,
						AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICE OR ANY
						INFORMATION PROVIDED IN CONNECTION WITH THE SERVICE. NIKO AND ITS
						LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE
						SERVICE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE. (B) THE
						SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED
						SUBSCRIBER DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF THE
						SERVICE OR ANY INFORMATION PROVIDED BY NIKO WILL MEET YOUR
						REQUIREMENTS OR EXPECTATIONS, OR (E) THE SERVICE, SOFTWARE OR THE
						SERVER(S) THAT MAKE THE SERVICE AVAILABLE ARE OR WILL BE FREE OF
						VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICE IS PROVIDED TO YOU
						STRICTLY ON AN "AS IS" BASIS. ALL WARRANTIES, WHETHER EXPRESS,
						IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY
						IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
						PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY
						DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY NIKO
						AND ITS LICENSORS.
					</Paragraph>
					<Title level={3} className="subTitle">
						10. Term and Termination
					</Title>
					<Paragraph>
						a) The term of this Agreement will be indicated on your invoice
						(“Term”). Such Term will automatically renew for another successive
						period of the same length, unless either party gives notice to the
						other at least thirty (30) days prior to the termination date of the
						Term. You are responsible for paying for the Service for the entire
						term (whether initial or any renewal), whether or not the Service is
						actively used.
					</Paragraph>
					<Paragraph>
						b) Any breach of your payment obligations, unauthorized use of the
						Niko IP or Service, or your breach or failure to comply with the
						terms and conditions hereof, will be deemed a material breach of
						this Agreement. Niko, in its sole discretion, may immediately
						terminate your account or use of the Service if you materially
						breach this Agreement. All unpaid fees and charges will become
						immediately due and payable upon any termination.
					</Paragraph>

					<Title level={3} className="subTitle">
						11. Limitation of Liability
					</Title>
					<Paragraph>
						IN NO EVENT WILL NIKO'S AGGREGATE LIABILITY UNDER THIS AGREEMENT
						EXCEED THE AMOUNTS ACTUALLY PAID BY YOU IN THE SIX (6) MONTH PERIOD
						IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. IN NO
						EVENT WILL NIKO OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY
						INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR
						OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE,
						PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY
						WAY CONNECTED WITH THE SERVICE OR THE WEBSITE, INCLUDING BUT NOT
						LIMITED TO THE USE OR INABILITY TO USE THE SERVICE, ANY
						INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE,
						EVEN IF NIKO OR NIKO’S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE
						POSSIBILITY OF SUCH DAMAGES.
					</Paragraph>

					<Title level={3} className="subTitle">
						12. Additional Rights
					</Title>
					<Paragraph>
						Certain states and/or jurisdictions do not allow the exclusion of
						implied warranties or limitation of liability for incidental,
						consequential or certain other types of damages, so the exclusions
						set forth above may not apply to you.
					</Paragraph>

					<Title level={3} className="subTitle">
						13. Notice
					</Title>
					<Paragraph>
						Notices hereunder will be effective when received and will be
						sufficient if given in writing, hand-delivered, sent by telefax or
						email with confirmation of receipt, sent by U.S. mail, return
						receipt requested, or sent by overnight courier service and
						addressed to the receiving party’s addresses provided by the party.
					</Paragraph>

					<Title level={3} className="subTitle">
						14. Assignment
					</Title>
					<Paragraph>
						Neither party may assign or transfer this Agreement (whether by
						operation of law or otherwise) without the prior written approval of
						the other party. In the event that the Subscriber undergoes any
						'change of control' resulting from a merger, consolidation, stock
						transfer or asset sale, Subscriber must promptly provide notice to
						Niko. Any such 'change of control' provides Niko sole discretion to
						terminate this Agreement unless otherwise mutually agreed by the
						parties in advance writing. Notwithstanding the foregoing, Niko may
						assign this Agreement without your consent to a parent or subsidiary
						of Niko, or in connection with any merger or acquisition to which
						Niko is a party, provided that such assignee agrees in writing to
						the terms and conditions of this Agreement. Any attempted assignment
						in violation of this Section 14 will be void.
					</Paragraph>
					<Title level={3} className="subTitle">
						15. Modifications
					</Title>
					<Paragraph>
						Niko reserves the right to modify its privacy policy in its
						reasonable discretion from time to time, effective upon posting of
						an updated version of such statements on the Service or on its
						Website at{" "}
						<Link href="https://iq.nikopartners.com" target="_blank">
							https://iq.nikopartners.com
						</Link>
						.
					</Paragraph>

					<Title level={3} className="subTitle">
						16. Force Majeure
					</Title>
					<Paragraph>
						Niko is not responsible for delays or failure to perform any of its
						obligations hereunder due to causes beyond its reasonable control
						including, but not limited to, war, riots, natural disasters,
						terrorism, power or telecommunications failures or unauthorized
						network intrusions. The affected party will promptly inform the
						other party of any force majeure event, and either party may require
						a renegotiation of the schedules set forth in the Pricing Proposal.
						However, if a force majeure event continues for more than 60 days,
						either party may terminate the Agreement.
					</Paragraph>

					<Title level={3} className="subTitle">
						17. General
					</Title>
					<Paragraph>
						(a) This Agreement will be governed by California law and applicable
						United States federal law, without regard to any choice or conflicts
						of law provisions. Any disputes, actions, claims or causes of action
						arising out of or in connection with this Agreement or the Service
						will be subject to the exclusive jurisdiction of the state and
						federal courts located in the Northern District of California.
					</Paragraph>
					<Paragraph>
						(b) No provisions of any purchase order, preprinted form or document
						will add to or vary the terms and conditions of this Agreement. If
						any provision of this Agreement is held by a court of competent
						jurisdiction to be invalid or unenforceable, the remaining
						provisions will remain in full force and effect, and the parties
						will negotiate in good faith a substitute valid and enforceable
						provision that most nearly gives effect to the parties' intent in
						entering into this Agreement.
					</Paragraph>
					<Paragraph>
						(c) No joint venture, partnership, employment, or agency
						relationship exists between you and Niko as a result of this
						Agreement or your use of the Service. Neither party will have the
						authority to enter into any contracts in the name of or on behalf of
						the other party or otherwise bind such other party.
					</Paragraph>
					<Paragraph>
						(d) The failure of either party to enforce any right or provision in
						this Agreement will not constitute a waiver of such right or
						provision unless acknowledged and agreed to by such party in
						writing. In any action to enforce this Agreement, the prevailing
						party will be entitled to its attorneys’ fees in addition to any
						other recovery awarded.
					</Paragraph>
					<Paragraph>
						(e) The provisions of Sections 4, 5, 8, 9, and 11 will survive the
						termination of this Agreement and continue thereafter in full force
						and effect in accordance with their respective terms as will any
						accrued payment or tax obligation of either party or any cause of
						action or claim of either party, whether in law or in equity,
						accrued or to accrue because of any breach or default.
					</Paragraph>

					<Title level={3} className="subTitle">
						18. Entire Agreement
					</Title>
					<Paragraph>
						This Agreement, executed by the parties, contains the entire
						agreement of the parties with respect to the subject matter hereof,
						and any warranty, representation, promise, or condition not
						incorporated herein will not be binding upon either party.
					</Paragraph>

					<Title level={3} className="subTitle">
						19. Questions or Additional Information
					</Title>
					<Paragraph>
						If you have questions regarding this Agreement or wish to obtain
						additional information regarding the Service or Website, please
						email:{" "}
						<Link href="mailto:support@nikopartners.com">
							support@nikopartners.com
						</Link>
					</Paragraph>
				</Typography>
			</Content>
		</Layout>
	);
};

export default TermsOfUse;
