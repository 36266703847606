import React, { useEffect, useState } from "react";
import { Tabs, Empty } from "antd";
import { NTab, EGet } from "../../../models/contants";
import { effect, useStore } from "../../../utils/dva16";
import ICafeGame from "./ICafeGame";
import  Mentain  from "../../../utils/mentain.jsx";	
import ICafeTable from "./ICafeTable";

const { TabPane } = Tabs;

const ICafeTabs = () => {
	const { tabList } = useStore(NTab);
	const [activeTab, setActiveTab] = useState("2");

	useEffect(() => {
		effect(NTab, EGet, { page_name: "icafe" });
	}, []);

	const onChange = (key) => {
		setActiveTab(key);
	};

	const customizeRenderEmpty = () => (
		<Mentain />
	
	);

	return (
		<>
			<Tabs
				onChange={onChange}
				activeKey={activeTab}
				type="card"
				className="custom-tabs"
			>
				{tabList?.[0]?.bi_tab == 1 && (
					<TabPane tab="Dashboard" key="1">
						<ICafeGame />
					</TabPane>
				)}
				{tabList?.[0]?.table_tab == 1 && (
					<TabPane tab="Table" key="2">
						<ICafeTable />
					</TabPane>
				)}
			</Tabs>
			{tabList?.[0]?.bi_tab != 1 && tabList?.[0]?.table_tab != 1
				? customizeRenderEmpty()
				: ""}
		</>
	);
};

export default ICafeTabs;
