import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
	Table,
	Checkbox,
	Modal,
	Button,
	Card,
	DatePicker,
	Row,
	Col,
	Form,
	Typography,
	Collapse,
} from "antd";
import Checked from "@/assets/new_style/profile/icon_check-green@2x.png";
import UnChecked from "@/assets/new_style/profile/icon_x@2x.png";
import formatDate from "@/utils/dateFormater";
import E from "../../../../models/E";
import { find, union } from "lodash";
import moment from "moment";
import { NLogin, EGet } from "../../../../models/contants";
import { effect, useStore } from "../../../../utils/dva16";
import CustomEmpty from "@/utils/CustomEmpty";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
const { RangePicker } = DatePicker;
export default (props) => {
	const [editForm] = Form.useForm();
	const { currentSubscribe } = useStore(NLogin);
	const { Text } = Typography;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const [module, setModule] = useState([
		{ id: 5, name: "Knowledge Briefs", value: "Exclusives" },
		{ id: 1, name: "VANA", value: "InsightsFeed" },
		{ id: 10, name: "Market Model", value: "MarketModel" },
		{ id: 2, name: "Esports", value: "Esport" },
		{ id: 7, name: "Games", value: "Games" },
		{ id: 8, name: "Organizations", value: "Organizations" },
		{ id: 3, name: "Financial Data", value: "Finacial" },
		{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
		// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
		// { id: 6, name: 'Transactions', value: 'Transactions' },
	]);
	useEffect(() => {
		effect(NLogin, EGet, {});
	}, []);
	const { Panel } = Collapse;
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	//组件渲染时根据变量“subscribe”和“visible”更新“module”变量的值，并更新antd表单中的时间字段。 | During component rendering, update the value of the "module" variable based on the variables "subscribe" and "visible", and update the time field in the ant form.
	useEffect(() => {
		if (currentSubscribe?.[0] && currentSubscribe?.[0]?.module) {
			for (let i in currentSubscribe?.[0]?.module) {
				let item = find(module, { value: i });
				if (item) {
					item.region = currentSubscribe?.[0]?.module[i];
				}
			}
			setModule(module.concat());
		} else {
			setModule([
				{ id: 5, name: "Knowledge Briefs", value: "Exclusives" },
				{ id: 1, name: "VANA", value: "InsightsFeed" },
				{ id: 10, name: "Market Model", value: "MarketModel" },
				{ id: 2, name: "Esports", value: "Esport" },
				{ id: 7, name: "Games", value: "Games" },
				{ id: 8, name: "Organizations", value: "Organizations" },
				{ id: 3, name: "Financial Data", value: "Finacial" },
				{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
				// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
				// { id: 6, name: 'Transactions', value: 'Transactions' },
			]);
		}
		editForm?.setFieldsValue({
			startDate:
				currentSubscribe?.[0] && moment(currentSubscribe?.[0]?.subscribeDate),
			endDate:
				currentSubscribe?.[0] && moment(currentSubscribe?.[0]?.expiredDate),
		});
	}, [currentSubscribe?.[0]]);
	const renderEditSubScription = () => {
		const columns = [
			{ title: "Module", dataIndex: "name", key: "name" },
			{
				title: E?.mainRegion?.China,
				dataIndex: "China",
				key: "China",
				align: "center",
				render: (text, record, index) => {
					return (
						<img
							style={{ width: "2rem" }}
							src={
								record?.region &&
								record?.region?.indexOf(E?.mainRegion?.China) > -1
									? Checked
									: UnChecked
							}
							alt=""
						/>
					);
				},
			},
			{
				title: E?.mainRegion?.EastAsia,
				dataIndex: "Ease Asia",
				key: "Ease Asia",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.EastAsia) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.SEA,
				dataIndex: "SEA",
				key: "SEA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.SEA) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.MENA,
				dataIndex: "MENA",
				key: "MENA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.MENA) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.India,
				dataIndex: "India",
				key: "India",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.India) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.Global,
				dataIndex: "Global",
				key: "Global",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.Global) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: "All Regions",
				dataIndex: "ALL",
				key: "ALL",
				align: "center",
				render: (text, record, index) => {
					return (
						<img
							style={{ width: "2rem" }}
							src={
								record?.region && record?.region?.length !== 6
									? UnChecked
									: Checked
							}
							alt=""
						/>
					);
				},
			},
		];

		return (
			<Card bordered={false}>
				<Form
					form={editForm}
					style={{ marginBottom: "1rem", marginLeft: ".4rem" }}
				>
					<Row gutter={8} style={{ fontWeight: "bold" }}>
						{currentSubscribe?.[0] &&
							formatDate(new Date(currentSubscribe?.[0]?.subscribeDate))}

						<span
							style={{
								marginLeft: "10px",
								marginRight: "10px",
								fontWeight: "normal",
							}}
						>
							to
						</span>
						{currentSubscribe?.[0] &&
							formatDate(new Date(currentSubscribe?.[0]?.expiredDate))}
					</Row>
				</Form>
				{!isMobile ? (
					<Table
						locale={{ emptyText: CustomEmpty }}
						scroll={{ x: "max-content" }}
						bordered
						columns={columns}
						dataSource={module}
						pagination={false}
					/>
				) : (
					<Collapse
						accordion
						activeKey={activeKey}
						onChange={handleCollapseChange}
						className="MobileList"
						expandIconPosition="right"
						expandIcon={({ isActive }) => (
							<div className="custom-arrow">
								<img src={isActive ? reverseArrow : iconArrow} alt="arrow" />
							</div>
						)}
					>
						{module?.map((record) => {
							return (
								<Panel
									header={
										<div className="panel-header">
											<div style={{ display: "flex" }}>
												<div>{record?.name}</div>
											</div>
										</div>
									}
								>
									<Row>
										<Col span={12}>{E?.mainRegion?.China}</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region &&
													record?.region?.indexOf(E?.mainRegion?.China) > -1
														? Checked
														: UnChecked
												}
												alt=""
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>{E?.mainRegion?.EastAsia}</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region &&
													record?.region?.indexOf(E?.mainRegion?.EastAsia) > -1
														? Checked
														: UnChecked
												}
												alt=""
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>{E?.mainRegion?.SEA}</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region &&
													record?.region?.indexOf(E?.mainRegion?.SEA) > -1
														? Checked
														: UnChecked
												}
												alt=""
											/>
										</Col>
									</Row>

									<Row>
										<Col span={12}>{E?.mainRegion?.MENA}</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region &&
													record?.region?.indexOf(E?.mainRegion?.MENA) > -1
														? Checked
														: UnChecked
												}
												alt=""
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>{E?.mainRegion?.India}</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region &&
													record?.region?.indexOf(E?.mainRegion?.India) > -1
														? Checked
														: UnChecked
												}
												alt=""
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>{E?.mainRegion?.Global}</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region &&
													record?.region?.indexOf(E?.mainRegion?.Global) > -1
														? Checked
														: UnChecked
												}
												alt=""
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>All Regions</Col>
										<Col span={12}>
											{" "}
											<img
												src={
													record?.region && record?.region?.length !== 6
														? UnChecked
														: Checked
												}
												alt=""
											/>
										</Col>
									</Row>
									{/* <Row>
										{record?.[Object.keys(record || {})[0]]?.map(
											(item, index) => (
												<>
													<Col key={index} span={12}>
														{item}
													</Col>
													<Col key={index} span={12}>
														<img
															src={
																record?.region &&
																record?.region?.indexOf(E?.mainRegion?.SEA) > -1
																	? Checked
																	: UnChecked
															}
															alt=""
														/>
													</Col>
												</>
											)
										)}
									</Row> */}
								</Panel>
							);
						})}
					</Collapse>
				)}
			</Card>
		);
	};
	return <div>{renderEditSubScription()}</div>;
};
