import React, { useEffect, useState } from "react";
import { Table, Modal, Card, Form, Row } from "antd";
import E from "../../../../models/E";
import { find, union } from "lodash";
import formatDate from "@/utils/dateFormater";
import CustomEmpty from "@/utils/CustomEmpty";
import moment from "moment";
import Checked from "@/assets/new_style/profile/icon_check-green@2x.png";
import UnChecked from "@/assets/new_style/profile/icon_x@2x.png";

export default (props) => {
	const [editForm] = Form.useForm();
	const { visible, subscribe } = props;
	const [module, setModule] = useState([
		{ id: 5, name: "Knowledge Briefs", value: "Exclusives" },
		{ id: 1, name: "VANA", value: "InsightsFeed" },
		{ id: 10, name: "Market Model", value: "MarketModel" },
		{ id: 2, name: "Esports", value: "Esport" },
		{ id: 7, name: "Games", value: "Games" },
		{ id: 8, name: "Organizations", value: "Organizations" },
		{ id: 3, name: "Financial Data", value: "Finacial" },
		{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
		// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
		// { id: 6, name: 'Transactions', value: 'Transactions' },
	]);
	//组件渲染时根据变量“subscribe”和“visible”更新“module”变量的值，并更新antd表单中的时间字段。 | During component rendering, update the value of the "module" variable based on the variables "subscribe" and "visible", and update the time field in the ant form.
	useEffect(() => {
		if (visible) {
			if (subscribe && subscribe?.module) {
				for (let i in subscribe?.module) {
					let item = find(module, { value: i });
					if (item) {
						item.region = subscribe?.module[i];
					}
				}
				setModule(module.concat());
			} else {
				setModule([
					{ id: 5, name: "Knowledge Briefs", value: "Exclusives" },
					{ id: 1, name: "VANA", value: "InsightsFeed" },
					{ id: 10, name: "Market Model", value: "MarketModel" },
					{ id: 2, name: "Esports", value: "Esport" },
					{ id: 7, name: "Games", value: "Games" },
					{ id: 8, name: "Organizations", value: "Organizations" },
					{ id: 3, name: "Financial Data", value: "Finacial" },
					{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
					// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
					// { id: 6, name: 'Transactions', value: 'Transactions' },
				]);
			}
			editForm?.setFieldsValue({
				time:
					(subscribe && [
						moment(subscribe?.subscribeDate),
						moment(subscribe?.expiredDate),
					]) ||
					null,
			});
		}
	}, [subscribe, visible]);
	const renderEditSubScription = () => {
		const columns = [
			{ title: "Buckets / Section", dataIndex: "name", key: "name" },
			{
				title: E?.mainRegion?.China,
				dataIndex: "China",
				key: "China",
				align: "center",
				render: (text, record, index) => {
					return (
						<img
							style={{ width: "2rem" }}
							src={
								record?.region &&
								record?.region?.indexOf(E?.mainRegion?.China) > -1
									? Checked
									: UnChecked
							}
							alt=""
						/>
					);
				},
			},
			{
				title: E?.mainRegion?.EastAsia,
				dataIndex: "Ease Asia",
				key: "Ease Asia",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.EastAsia) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.SEA,
				dataIndex: "SEA",
				key: "SEA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.SEA) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.MENA,
				dataIndex: "MENA",
				key: "MENA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.MENA) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.India,
				dataIndex: "India",
				key: "India",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.India) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.Global,
				dataIndex: "Global",
				key: "Global",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<img
								style={{ width: "2rem" }}
								src={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.Global) > -1
										? Checked
										: UnChecked
								}
								alt=""
							/>
						)
					);
				},
			},
			{
				title: "All Regions",
				dataIndex: "ALL",
				key: "ALL",
				align: "center",
				render: (text, record, index) => {
					return (
						<img
							style={{ width: "2rem" }}
							src={
								record?.region && record?.region?.length !== 6
									? UnChecked
									: Checked
							}
							alt=""
						/>
					);
				},
			},
		];
		return (
			<Card bordered={false}>
				<Form form={editForm}>
					<Form.Item label="Duration" name="time">
						<Row gutter={8} style={{ fontWeight: "bold" }}>
							{subscribe && formatDate(new Date(subscribe?.subscribeDate))}

							<span
								style={{
									marginLeft: "10px",
									marginRight: "10px",
									fontWeight: "normal",
								}}
							>
								to
							</span>
							{subscribe && formatDate(new Date(subscribe?.expiredDate))}
						</Row>
					</Form.Item>
				</Form>
				<Table
					locale={{ emptyText: CustomEmpty }}
					scroll={{ x: "max-content" }}
					bordered
					columns={columns}
					dataSource={module}
					pagination={false}
				/>
			</Card>
		);
	};
	return (
		<Modal
			okButtonProps={{ className: "btn_2" }}
			cancelButtonProps={{ style: { display: "none" } }}
			{...props}
		>
			{renderEditSubScription()}
		</Modal>
	);
};
