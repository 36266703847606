import React, { useState } from "react";
import "../Login/index.less";
import { Button, Typography, Form, Input } from "antd";
import { effect } from "../../utils/dva16";
import { NLogin, EPostPassWordReset } from "../../models/contants";
const { Link } = Typography;
import * as qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

export default (props) => {
	const [editForm] = Form.useForm();
	const history = useHistory();
	const location = useLocation();
	const [sendDone, setSendDone] = useState(false);

	const onOks = async () => {
		try {
			await editForm.validateFields();
			let values = editForm.getFieldValue();
			effect(NLogin, EPostPassWordReset, values, () => {
				setSendDone(true);
			});
		} catch (e) {}
	};

	return (
		<div>
			<div className="LoginBox">
				<header>
					<div className="LogoBox">
						<Link herf="">
							<img src={require("@/assets/icon/logo_niko.png")?.default} />
						</Link>
					</div>
				</header>
				{sendDone ? (
					<div className="LoginFormBox">
						<div className="LoginTitleBox">
							<h2 style={{ fontSize: "1.5rem" }}>
								A Password Reset email has been sent
							</h2>
							<h3>Please check your email for further instructions.</h3>
						</div>
						<Form form={editForm} autoComplete="off">
							<Form.Item>
								<Button
									onClick={() => {
										window.location.replace(
											"https://nikopartners.com/contact/"
										);
									}}
									type="primary"
									size="large"
									className="Login_Btn_Box_Reset_m"
								>
									Contact Support
								</Button>
							</Form.Item>
							<Form.Item>
								<Button
									type="primary"
									size="large"
									className="Login_Btn_Box_Reset"
									onClick={() => history.replace("/login")}
								>
									Back to Login
								</Button>
							</Form.Item>
						</Form>
					</div>
				) : (
					<div className="LoginFormBox">
						<div className="LoginTitleBox">
							<h2>Reset your password</h2>
							<h3>
								Enter your registered email address to reset your password.
							</h3>
						</div>
						<Form form={editForm} autoComplete="off">
							<Form.Item
								name="email"
								rules={[
									{ required: true, message: "Please input your email!" },
								]}
							>
								<Input
									placeholder="Please enter Email Address"
									style={{ width: "100%", fontSize: "1.3rem" }}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									htmlType="submit"
									onClick={() => {
										onOks();
									}}
									// suffixIcon={<ArrowRightOutlined />}
									type="primary"
									size="large"
									className="Login_Btn_Box_Reset_small"
								>
									Next <RightOutlined />
								</Button>
							</Form.Item>
							<Form.Item>
								<Button
									type="primary"
									size="large"
									className="Login_Btn_Box_Reset"
									onClick={() => history.replace("/login")}
								>
									Back to Login
								</Button>
							</Form.Item>
						</Form>
					</div>
				)}
				<div className="footer">
					<Link
						className="linkItem"
						onClick={() => history.push("/term_and_conditions")}
					>
						Terms and conditions
					</Link>
					<span style={{ margin: "0 1rem" }}> - </span>
					<Link
						className="linkItem"
						onClick={() => history.push("/privacy_policy")}
					>
						Privacy policy
					</Link>
				</div>
			</div>
		</div>
	);
};
