import { Button, Empty } from "antd";
import notFound from "@/assets/err/icon_subscription-required@2x.png";
//获取本地存储中的“xf3Token”值，接口请求时需要携带token | Obtain the "xf3Token" value in local storage, which needs to be carried with the token during interface requests
export function getToken() {
	return localStorage.getItem("xf3Token");
}
//将传入的“token”保存到本地存储的“xf3Token”中，以便在接口请求时使用 | Save the incoming 'token' to the locally stored 'xf3Token' for use in interface requests
export function setToken(token) {
	return localStorage.setItem("xf3Token", token);
}

export function customizeRenderEmpty() {
	return (
		<div
			style={{
				display: "flex",

				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh", // Full viewport height
				textAlign: "center",
			}}
		>
			<img
				src={notFound}
				alt="404"
				style={{ maxWidth: "200px", marginBottom: "20px" }}
			/>
			<div style={{ marginLeft: "2rem" }}>
				<h1 style={{ fontWeight: 700, fontSize: "2rem", textAlign: "left" }}>
					Subscription Required
				</h1>
				<p style={{ maxWidth: "25rem", textAlign: "left" }}>
					Your subscription does not include access to this service. Please
					contact{" "}
					<a href="mailto:support@nikopartners.com" className="linkItem">
						support@nikopartners.com
					</a>{" "}
					for more information.
				</p>
			</div>
		</div>
	);
}

export const priceSplitter = (number) =>
	(number && number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || "0";
