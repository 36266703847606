import React, { useEffect, useState } from "react";
import imgSource from "../../../assets";
import CustomModal from "../../../components/modal";
import iconFilter from "@/assets/icon/icon_search.png";
import IconRegion from "@/assets/exclusivesicons/icon_globe@2x.png";
import IconArea from "@/assets/exclusivesicons/icon_map-pin@2x.png";
import IconShare from "@/assets/exclusivesicons/icon_share-2@2x.png";
import Iconpdf from "@/assets/exclusivesicons/icon_file-text@2x.png";
import Iconarrow from "@/assets/exclusivesicons/icon_arrow-down@2x.png";

import { useLocation } from "react-router-dom";
import {
	Button,
	Col,
	Form,
	Row,
	Select,
	Input,
	Space,
	DatePicker,
	Pagination,
	Typography,
	Spin,
	Empty,
} from "antd";
import "./index.less";
import PdfViewer from "./components/toolBar/toolBar";
const { Option } = Select;
import { debounce } from "lodash";
import {
	EGet,
	EGetActive,
	EGetAgencies,
	EGetArea,
	EGetCompany,
	EGetSponsor,
	EGetType,
	EPost,
	NCommon,
	NExclusives,
	NGame,
	NGenre,
	NOrganizationMulti,
	NPlatform,
	NRegion,
	EGetRegion,
	NLogin,
} from "../../../models/contants";
import sortData from "../../../utils/sorter";
import formatDate from "@/utils/dateFormater";
import { effect, useStore } from "../../../utils/dva16";
import E from "../../../models/E";
import moment from "moment";
import { customizeRenderEmpty } from "../../../utils/utils";
import { Helmet } from "react-helmet";
import CustomEmpty from "@/utils/CustomEmpty";
export default (props) => {
	const { history } = props;
	const {
		location: { state },
	} = history;
	const [form] = Form.useForm();
	const { OrganizationMultiList, OrganizationCompany } =
		useStore(NOrganizationMulti);
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const { gameSearch } = useStore(NGame);
	const { GenreList } = useStore(NGenre);
	const { PlatformData } = useStore(NPlatform);
	// const { sponsorList, agencyList } = useStore(NCommon);
	const { exclusivesList, loading, activeNew } = useStore(NExclusives);
	const [searchValues, setSearchValues] = useState();
	const [more, setMore] = useState(false);
	// const { currentSubscribe, userInfo } = useStore(NLogin);
	const paginationLocale = {
		items_per_page: "per page",
	};
	const { Text } = Typography;
	const [filterValues, setFilterValues] = useState([]);
	const location = useLocation();

	useEffect(() => {
		// const targetElement = document.getElementById("targetAnchor");

		// if (targetElement) {
		// 	targetElement.scrollIntoView({ behavior: "smooth" });
		// }
	}, [location]);
	useEffect(() => {
		if (state) {
			form.setFieldsValue({
				...state,
			});
			if (state?.company) {
				form.setFieldsValue({
					company: state?.company,
				});
				setFilterValues((o) => [...o, state.company]);
				handleValuesChange(state);
			}
			if (state.game) {
				setFilterValues((o) => [...o, state.game]);
				form.setFieldsValue({
					game: state?.name,
				});
				handleValuesChange(state);
			}
			setSearchValues({ ...searchValues, ...state });
			setMore(true);
		}
		// effect(NOrganizationMulti, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 25 });
		effect(NGenre, EGet, { page: 1, perPage: 30 });
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NPlatform, EGetType, {});
		effect(NExclusives, EGet, { ...state });
		effect(NExclusives, EGetActive, {});
		effect(NOrganizationMulti, EGetCompany, {
			page: 1,
			perPage: 10,
		});
		// effect(NCommon, EGetSponsor, { page: 1, perPage: 10 });
		// effect(NCommon, EGetAgencies, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NLogin, EGet, {});
	}, []);
	const onPageChange = (page, pageSize) => {
		if (page && pageSize) {
			effect(NExclusives, EGet, {
				page: page || 1,
				perPage: pageSize || 10,
				...searchValues,
			});
		}
	};
	const [showAllTags, setShowAllTags] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		if (state?.company) {
			values.company = state?.company;
		}
		if (state?.game) {
			values.game = state?.game;
		}
		// if (values?.date) {
		// values.startDate = moment(values?.date[0])?.format("YYYY-MM-DD HH:mm:ss");
		// values.endDate = moment(values?.date[1])?.format("YYYY-MM-DD HH:mm:ss");
		values.startDate = values?.startDate
			? moment(values?.startDate?._d)?.format("YYYY-MM-DD HH:mm:ss")
			: null;
		values.endDate = values?.endDate
			? moment(values?.endDate?._d)?.format("YYYY-MM-DD HH:mm:ss")
			: null;
		// }
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];

		values.platform = values?.platform?.map((areaValue) => {
			return PlatformData?.data?.find((item) => item.lookupValue === areaValue)
				?.id;
		});
		setSearchValues(values);
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		closeModal();
		setFilterValues(valuesArray);
		delete values?.date;
		effect(NExclusives, EGet, { ...values, page: 1, perPage: 10 });
	};

	const onReset = () => {
		closeModal();
		setSearchValues();
		form.resetFields();
		setFormValues([]);
		setFilterValues([]);
		effect(NExclusives, EGet, { page: 1, perPage: 10 });
	};
	// const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];
		commonAreaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
		setFormValues((prevValues) => ({
			...prevValues,
			["region"]: Array.from(new Set(updatedRegionArr)),
		}));
	}
	//用户操作判断 | User operation judgment
	const onAction = (id, mode, url, title) => {
		if (mode == E?.operate?.Share) {
			effect(NExclusives, EPost, { exclusivesID: id, type: E?.operate?.Share });
			//跳转outlook并且填充内容
			const body = `Hello, \n\n Please copy this link to share the Niko Knowledge Briefs report:${url} \n\n Thank You`;
			window.open(
				`https://outlook.live.com/owa/?path=/mail/action/compose&to=&subject=${`Niko Knowledge Briefs-${title}`}&body=${encodeURIComponent(
					body
				)}`
			);
		} else {
			effect(
				NExclusives,
				EPost,
				{ exclusivesID: id, type: E?.operate?.Download },
				() => {}
			);
			//查看PDF | View PDF
			window.open(url);
		}
	};

	//默认展示活跃文章 | Default display of active articles
	const [pdfLoading, setPdfLoading] = useState(true);
	const [retryCount, setRetryCount] = useState(0);
	const retryDelay = 3000; // 2-second delay between retries

	useEffect(() => {
		// Reset states whenever `activeNew.pdf` changes
		if (activeNew?.pdf) {
			console.log(pdfLoading, "pdfLoading");
			if (pdfLoading === true) {
				console.log("PDF URL updated:", activeNew.pdf);
				setTimeout(() => {
					setRetryCount((prev) => prev + 1);
					console.log(`Retrying... Attempt ${retryCount + 1}`);
					return () => clearTimeout(retryTimeout);
				}, retryDelay);
			} else {
				setRetryCount(0);
			}
		}
	}, [activeNew?.pdf, retryCount]);

	const handleLoad = () => {
		if (pdfLoading === true) {
			setPdfLoading(false); // PDF loaded successfully
			setRetryCount(0);
			console.log("PDF loaded successfully");
		}
	};

	const defaultShow = () => {
		const date = new Date(activeNew && activeNew?.date);
		const formattedDate = formatDate(date);
		return (
			<div className="active">
				<div className="leftSection">
					{/* <div className="head"> */}
					<div className="left_title">
						<span>{activeNew?.title}</span>
					</div>
					{/* </div> */}
					<div className="controls">
						<div className="right_time">{formattedDate}</div>

						<div
							className="btn_action"
							onClick={() => {
								onAction(
									activeNew?.id,
									E?.operate?.Share,
									activeNew?.pdf,
									activeNew?.title
								);
							}}
						>
							<div className="icon_action">
								<img src={imgSource?.icon_share} className="icon"></img>
							</div>
							<span className="option">Share</span>
						</div>
					</div>
					<div className="title">{activeNew?.subHeader}</div>
				</div>

				<div className="rich_pdf">
					{/* {activeNew?.pdf && <PdfViewer pdfUrl={activeNew.pdf} />} */}
					{/* <div style={{ position: "relative", width: "100%", height: "100%" }}>
				
						{activeNew?.pdf && (
							<iframe
								key={activeNew?.pdf}
								src={`https://docs.google.com/viewer?url=${encodeURIComponent(
									activeNew?.pdf
								)}&embedded=true`}
								width="100%"
								height="100%"
								style={{ border: "none" }}
							/>
						)}
				</div>  */}
					<div style={{ position: "relative", width: "100%", height: "100%" }}>
						{/* Overlay for loading or error message */}
						{activeNew?.pdf && (
							<>
								{/* {(pdfLoading || loading) && <p>Loading PDF...</p>} */}
								<Spin
									spinning={pdfLoading || loading}
									size="large"
									style={{
										position: "relative",
										width: "100%",
										height: "100%",
									}}
									className="pdfLooder"
								>
									<iframe
										src={`https://docs.google.com/viewer?url=${encodeURIComponent(
											activeNew?.pdf
										)}&embedded=true&retry=${retryCount}_${Date.now()}`} // Unique URL with retry and timestamp
										width="100%"
										height="100%"
										style={{
											border: "none",

											// display: pdfLoading || loading ? "none" : "block",
										}}
										onLoad={handleLoad}
										// onError={handleError}
									/>
								</Spin>
							</>
						)}
					</div>

					{/* {activeNew?.pdf && <embed src={activeNew.pdf}></embed>} */}
				</div>
			</div>
		);
	};
	const searchByTags = (state) => {
		effect(NExclusives, EGet, {
			page: 1,
			perPage: 10,
			field: "date",
			isDesc: "desc",
			...state,
		});
		if (state) {
			form.setFieldsValue({
				...state,
			});
		}
	};
	const dataBlock = (v, i) => {
		const showAll = showAllTags[i] || false;

		const date = new Date(v?.date);
		const formattedDate = formatDate(date);

		return (
			<div className="data_item" key={i}>
				<div className="head">
					<span className="title">{v?.title}</span>
					<span className="time">{formattedDate}</span>
				</div>
				<div className="area_Tag_List_Box">
					{v?.area?.length > 0 && (
						<span
							className="listLink"
							onClick={() => {
								const state = {
									area: v?.area,
								};
								searchByTags(state);
							}}
						>
							<img
								src={imgSource?.map_icon}
								style={{
									marginRight: "5px",
									width: "20px",
								}}
							></img>
							{v?.area?.length > 0 && v?.area?.join(", ")}
						</span>
					)}
					{v?.region?.length > 0 && (
						<span
							className="listLink"
							onClick={() => {
								const state = {
									region: v?.region,
								};
								searchByTags(state);
							}}
						>
							<img
								src={imgSource?.globe_gray_icon}
								style={{
									marginRight: "5px",
									width: "20px",
								}}
							></img>
							{v?.region?.length > 0 && v?.region?.join(", ")}
						</span>
					)}
					{/* {item?.type && (
							<span
								className="listLink"
								onClick={() => {
									const state = {
										type: item?.type,
									};
									searchByTags(state);
								}}
							>
								<img
									src={imgSource?.folder_icon}
									style={{
										marginRight: "5px",
										width: "20px",
									}}
								></img>
								{item?.type}
							</span>
						)} */}
				</div>
				<div className="Tag_List_Box">
					{v?.withGames
						?.slice(0, showAll ? v?.withGames?.length : 3)
						?.map((vi, iii) => (
							<span
								key={iii}
								className="game-title"
								onClick={() => {
									history.push(`/games_organizations/games/details/${vi?.id}`);
								}}
							>
								{vi?.gameEn}
							</span>
						))}
					{v?.withCompanys
						?.slice(0, showAll ? v?.withCompanys?.length : 3)
						?.map((vi, ii) => (
							<span
								key={ii}
								className="org-title"
								onClick={() => {
									history.push(
										`/games_organizations/organizations/details/${vi?.id}`
									);
								}}
							>
								{vi?.name}
							</span>
						))}

					{(v?.withCompanys?.length > 3 || v?.withGames?.length > 3) && (
						<span className="show-all" onClick={() => toggleShowAll(i)}>
							{showAll ? "Show less..." : "Show all..."}
						</span>
					)}
				</div>
				<div className="mid">{v?.subHeader}</div>
				<div className="bottom">
					<div
						className="action"
						onClick={() => {
							onAction(v?.id, E?.operate?.Download, v?.pdf, v?.title);
						}}
					>
						<img src={Iconpdf} />
						<span>Show PDF</span>
					</div>
					<div
						className="action"
						onClick={() => {
							onAction(v?.id, E?.operate?.Share, v?.pdf, v?.title);
						}}
					>
						<img src={IconShare} />
						<span>Share</span>
					</div>
				</div>
			</div>
		);
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGame = (value) => {
		// effect(NGame, EGet, { ...{ keyword: value }, page: 1, perPage: 10 });
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const onSearchSponsor = (value) => {
		effect(NCommon, EGetSponsor, {
			company: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchAgency = (value) => {
		effect(NCommon, EGetAgencies, {
			company: value,
			page: 1,
			perPage: 10,
		});
	};
	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "area") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	return (
		<div className="exclusives_container">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
	        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				{activeNew && defaultShow()}
				{exclusivesList?.error == 1009 && customizeRenderEmpty()}
				{exclusivesList?.error != 1009 && (
					<>
						{more == false && loading == false && (
							<div className="history_box">
								<Button
									type="primary"
									className="history"
									onClick={() => {
										setMore(true);
									}}
								>
									See Past Niko Knowledge Briefs
									<img
										style={{ marginLeft: "10px", width: "30px" }}
										src={Iconarrow}
									/>
								</Button>
							</div>
						)}
						{more == true && (
							<>
								<div className="exclusiveBox" id="targetAnchor">
									<div
										style={{
											display: "flex",
											justifyContent: "flex-end",
											height: "60px",
										}}
									>
										<Form
											onValuesChange={handleValuesChange}
											form={form}
											onFinish={onFinish}
											className="searchFormCard"
										>
											{" "}
											<Row style={{ padding: "0px", paddingTop: "10px" }}>
												<Form.Item name="keyWord" labelCol={{ span: 24 }}>
													<Input className="customInput" placeholder="Search" />
												</Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													className="applySearchBtn"
												>
													<img src={iconFilter} />
												</Button>
											</Row>
										</Form>
									</div>
									<CustomModal
										isModalOpen={isModalOpen}
										showModal={showModal}
										closeModal={closeModal}
										filterValues={filterValues}
										content={
											<>
												{" "}
												<Form
													onValuesChange={handleValuesChange}
													form={form}
													onFinish={onFinish}
												>
													<Row>
														<Col span={24}>
															<Form.Item
																name="area"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			AREA
																		</Text>
																		<Button
																			disabled={
																				!formValues.area ||
																				formValues.area.length === 0
																			}
																			className={
																				!formValues.area ||
																				formValues.area.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("area")}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
															>
																<Select
																	getPopupContainer={(triggerNode) =>
																		triggerNode?.parentNode
																	}
																	mode="multiple"
																	placeholder="Select"
																	showArrow
																	showSearch
																	onChange={(e) => {
																		findRegionType(e);
																	}}
																	onSearch={debounce((value) => {
																		const { page, perPage } = areaList;
																		effect(NRegion, EGetArea, {
																			page,
																			perPage,
																			area: value,
																		});
																	}, 300)}
																	filterOption={false}
																>
																	{sortData(areaList?.data, "area")?.map(
																		(record, index) => {
																			return (
																				<Option
																					key={record?.id}
																					value={record?.otherData}
																				>
																					{record?.otherData}
																				</Option>
																			);
																		}
																	)}
																</Select>
															</Form.Item>
														</Col>
														<Col span={24}>
															<Form.Item
																name="region"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			REGION
																		</Text>
																		<Button
																			disabled={
																				!formValues.region ||
																				formValues.region.length === 0
																			}
																			className={
																				!formValues.region ||
																				formValues.region.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("region")}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
															>
																<Select
																	showArrow
																	mode="multiple"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	placeholder="Select"
																>
																	{sortData(regionList, "region")?.map(
																		(vo, index) => (
																			<Option
																				key={index}
																				value={vo?.lookupValue}
																			>
																				{vo?.lookupValue}
																			</Option>
																		)
																	)}
																</Select>
															</Form.Item>
														</Col>

														<Col span={24}>
															<Form.Item
																name="keyWord"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			KEYWORD SEARCH
																		</Text>
																		<Button
																			disabled={
																				!formValues.keyWord ||
																				formValues.keyWord.length === 0
																			}
																			className={
																				!formValues.keyWord ||
																				formValues.keyWord.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("keyWord")}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
															>
																<Input
																	placeholder="Search"
																	autoComplete="off"
																/>
															</Form.Item>
														</Col>
														<Col span={24}>
															<Form.Item
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			PUBLISHED DATE
																		</Text>
																		<Form.Item shouldUpdate>
																			{({ getFieldValue }) => {
																				const startDate =
																					getFieldValue("startDate");
																				const endDate =
																					getFieldValue("endDate");
																				const isDisabled =
																					!startDate && !endDate;
																				return (
																					<Button
																						disabled={isDisabled}
																						className={
																							isDisabled
																								? "resetDisabled"
																								: "reset"
																						}
																						onClick={() => {
																							form.resetFields([
																								"startDate",
																								"endDate",
																							]);
																						}}
																						type="secondary"
																						style={{
																							marginLeft: "auto",
																							cursor: "pointer",
																						}}
																					>
																						Reset
																					</Button>
																				);
																			}}
																		</Form.Item>
																	</div>
																}
															>
																<Row gutter={16}>
																	<Col span={12}>
																		<Form.Item name="startDate" noStyle>
																			<DatePicker
																				format="MMM DD, YYYY"
																				getPopupContainer={(triggerNode) =>
																					triggerNode.parentNode
																				}
																				style={{ width: "100%" }}
																				placeholder="Start Date"
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={12}>
																		<Form.Item name="endDate" noStyle>
																			<DatePicker
																				format="MMM DD, YYYY"
																				getPopupContainer={(triggerNode) =>
																					triggerNode.parentNode
																				}
																				style={{ width: "100%" }}
																				placeholder="End Date"
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Form.Item>
														</Col>
														<Col span={24}>
															<Form.Item
																name="game"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			GAME
																		</Text>
																		<Button
																			disabled={
																				!formValues.game ||
																				formValues.game.length === 0
																			}
																			className={
																				!formValues.game ||
																				formValues.game.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("game")}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
															>
																<Select
																	getPopupContainer={(triggerNode) =>
																		triggerNode?.parentNode
																	}
																	showArrow
																	showSearch
																	filterOption={false}
																	placeholder="Select"
																	onSearch={debounce((value) => {
																		onSearchGame(value);
																	}, 100)}
																>
																	{sortData(gameSearch?.data, "gameEn")?.map(
																		(item, index) => {
																			return (
																				<Option key={index} value={item?.id}>
																					{item?.gameEn}
																				</Option>
																			);
																		}
																	)}
																</Select>
															</Form.Item>
														</Col>
														<Col span={24}>
															<Form.Item
																name="company"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			ORGANIZATION
																		</Text>
																		<Button
																			disabled={
																				!formValues.company ||
																				formValues.company.length === 0
																			}
																			className={
																				!formValues.company ||
																				formValues.company.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("company")}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
															>
																<Select
																	getPopupContainer={(triggerNode) =>
																		triggerNode?.parentNode
																	}
																	showSearch
																	showArrow
																	filterOption={false}
																	placeholder="Select"
																	onSearch={debounce((value) => {
																		onSearchCompany(value);
																	}, 300)}
																>
																	{sortData(OrganizationCompany?.data)?.map(
																		(item, index) => {
																			return (
																				<Option key={index} value={item?.id}>
																					{item?.name}
																				</Option>
																			);
																		}
																	)}
																</Select>
															</Form.Item>
														</Col>

														{/* <Col span={24}>
												<Form.Item name="sponsors" label="Sponsors">
													<Select
														mode="multiple"
														
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchSponsor(value);
														}, 300)}
													>
														{sortData(sponsorList?.data)?.map((item, index) => {
															return (
																<Option key={index} value={item?.id}>
																	{item?.name}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col> */}
														{/* <Col span={24}>
												<Form.Item
													name="regulatory"
													label="Regulatory Agencies"
												>
													<Select
														mode="multiple"
														
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchAgency(value);
														}, 300)}
													>
														{sortData(agencyList?.data)?.map((item, index) => {
															return (
																<Option key={index} value={item?.id}>
																	{item?.name}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col> */}
														<Col span={24}>
															<Form.Item
																name="genre"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			GENRE
																		</Text>
																		<Button
																			disabled={
																				!formValues.genre ||
																				formValues.genre.length === 0
																			}
																			className={
																				!formValues.genre ||
																				formValues.genre.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("genre")}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
															>
																<Select
																	getPopupContainer={(triggerNode) =>
																		triggerNode?.parentNode
																	}
																	showArrow
																	showSearch
																	filterOption={false}
																	placeholder="Select"
																	onSearch={debounce((value) => {
																		onSearchGenre(value);
																	}, 300)}
																>
																	{sortData(GenreList?.data, "genre")?.map(
																		(item, index) => {
																			return (
																				<Option key={index} value={item?.id}>
																					{item?.lookupValue}
																				</Option>
																			);
																		}
																	)}
																</Select>
															</Form.Item>
														</Col>
														<Col span={24}>
															<Form.Item
																name="platform"
																labelCol={{ span: 24 }}
																label={
																	<div className="headBox">
																		<Text className="label" type="secondary">
																			PLATFORM
																		</Text>
																		<Button
																			disabled={
																				!formValues.platform ||
																				formValues.platform.length === 0
																			}
																			className={
																				!formValues.platform ||
																				formValues.platform.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
																			onClick={() => resetField("platform")}
																			type="secondary"
																			style={{
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Button>
																	</div>
																}
																rules={[
																	{
																		required: false,
																		message: "Input something!",
																	},
																]}
															>
																<Select
																	mode="multiple"
																	getPopupContainer={(triggerNode) =>
																		triggerNode?.parentNode
																	}
																	showArrow
																	showSearch
																	filterOption={false}
																	placeholder="Select"
																	onSearch={debounce((value) => {
																		onSearchPlatform(value);
																	}, 300)}
																>
																	{sortData(
																		PlatformData?.data,
																		"platform"
																	)?.map((item, index) => {
																		return (
																			<Option
																				key={index}
																				value={item?.lookupValue}
																			>
																				{item?.lookupValue}
																			</Option>
																		);
																	})}
																</Select>
															</Form.Item>
														</Col>
														<Col span={24} style={{ textAlign: "right" }}>
															<Space>
																<Button
																	type="primary"
																	htmlType="submit"
																	className="applyBtn"
																>
																	Apply Now
																</Button>
																<Button
																	type="default"
																	onClick={onReset}
																	className="resetBtn"
																>
																	Reset All
																</Button>
															</Space>
														</Col>
													</Row>
												</Form>
											</>
										}
									/>
								</div>
								{/* <div className="form_box">
						
								</div> */}
								<div className="data_list">
									{exclusivesList?.data?.map((v, i) => {
										return dataBlock(v, i);
									})}
									{exclusivesList?.total == 0 &&
										exclusivesList?.error != 1009 && <CustomEmpty />}
								</div>
								<Row style={{ padding: "10px 10px 0 10px" }}>
									<Col span={24}>
										<Pagination
											current={exclusivesList?.page}
											total={exclusivesList?.total}
											pageSize={exclusivesList?.perPage || 10}
											onChange={onPageChange}
											showSizeChanger
											showQuickJumper
											showTotal={(total, range) =>
												`${range[0]}-${range[1]} of ${total} items`
											}
											locale={paginationLocale}
											itemRender={(page, type, originalElement) => {
												if (type === "page") {
													return <span>{page}</span>;
												}
												return originalElement;
											}}
											className="custom-pagination"
										/>
									</Col>
								</Row>
							</>
						)}
					</>
				)}
			</Spin>
		</div>
	);
};
